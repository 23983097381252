import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IColumn, ComboBox, IComboBoxOption } from "@fluentui/react";
import {
  Baustellen,
  BaustellenInput,
  GetBaustellenDocument,
  SaveBaustellenMutation,
  User,
  useGetBaustellenQuery,
  useSaveBaustellenMutation,
} from "../gql/graphql";
import DetailsListEditable from "../components/DetailsListEditable";
import { TextBoxColumn } from "../components/TextBoxColumn";
import { Page } from "../components/Page";
import { useHandleOfType } from "../useHandleOf";
import { Checkbox, Spinner } from "@fluentui/react-components";

export function BaustellenView(props: { user: User }) {
  const { data, loading } = useGetBaustellenQuery();
  const [baufuehrers, setBaufuehrers] = useState(
    data?.mitarbeiter.filter((pr) => pr.funktion === "Bauführer")
  );
  const [vorarbeiters, setVorarbeiters] = useState(
    data?.mitarbeiter.filter((pr) => pr.funktion === "Vorarbeiter")
  );

  const [loadet, setLoadet] = useState(false);
  const [baustellenrarr, setBaustellenarr] = useState<
    | (Partial<Baustellen> & {
        rowid: number;
        edited: boolean;
        removed: boolean;
      })[]
    | undefined
  >();

  const [save] = useSaveBaustellenMutation({
    refetchQueries: () => [{ query: GetBaustellenDocument }],
  });
  useEffect(() => {
    if (!loadet && !loading) {
      setLoadet(true);
      const sortedList = [...(data?.baustellen ?? [])]
        .sort((a, b) => {
          if (a.eingetragen && b.eingetragen) {
            return (
              new Date(a.eingetragen).valueOf() -
              new Date(b.eingetragen).valueOf()
            );
          } else {
            return (a.id ?? 0) - (b.id ?? 0);
          }
        })
        .map((pr, i) => ({
          ...pr,
          edited: false,
          removed: false,
          rowid: i,
        }))
        .sort((a, b) => b.rowid - a.rowid);
      // Update the state with a copy of the sorted list
      setBaustellenarr(sortedList);
      let vorarb = data?.mitarbeiter.filter(
        (pr) => pr.funktion === "Vorarbeiter"
      );
      let baufr = data?.mitarbeiter.filter((pr) => pr.funktion === "Bauführer");
      if (vorarb && baufr) {
        setBaufuehrers([...baufr]);
        setVorarbeiters([...vorarb]);
      }
    }
  }, [loadet, loading, data?.baustellen, data?.mitarbeiter]);

  const handleBaufuehrerChange = useHandleOfType<
    Partial<Baustellen> & { edited: boolean; removed: boolean }
  >("baufuehrer_Nr", setBaustellenarr, true);
  const handleInaktivChange = useHandleOfType<
    Partial<Baustellen> & { edited: boolean; removed: boolean }
  >("inaktiv", setBaustellenarr, false, true);
  const handleVorarbeiterChange = useHandleOfType<
    Partial<Baustellen> & { edited: boolean; removed: boolean }
  >("vorarbeiter_Nr", setBaustellenarr);
  const handleOrtChange = useHandleOfType<
    Partial<Baustellen> & { edited: boolean; removed: boolean }
  >("ort", setBaustellenarr);
  const handleBaustellenNrChange = useHandleOfType<
    Partial<Baustellen> & { edited: boolean; removed: boolean }
  >("baustellennr", setBaustellenarr);
  const handleBaustelleChange = useHandleOfType<
    Partial<Baustellen> & { edited: boolean; removed: boolean }
  >("baustelle", setBaustellenarr);
  const handleFirmaChange = useHandleOfType<
    Partial<Baustellen> & { edited: boolean; removed: boolean }
  >("firma_nr", setBaustellenarr, true);
  const handleStateChange = useHandleOfType<
    Partial<Baustellen> & { edited: boolean; removed: boolean }
  >("edited", setBaustellenarr, false, true);

  const handleMutateComplete = useCallback(
    (res: SaveBaustellenMutation) => {
      if (
        res.baustellen.save.added &&
        res.baustellen.save.added !== null &&
        res.baustellen.save.updated &&
        res.baustellen.save.updated !== null
      ) {
        let nums = Math.max(...(baustellenrarr?.map((p) => p.rowid) ?? [])) + 1;
        let vals = res.baustellen.save.added.map((pr, num) => {
          let i = nums + num;
          return { ...pr, rowid: i, edited: false, removed: false };
        });
        let vals1 = res.baustellen.save.updated.map((pr, num) => {
          let i = nums + num;
          return { ...pr, rowid: i, edited: false, removed: false };
        });
        if (baustellenrarr) {
          let tmparr = [
            ...baustellenrarr.filter(
              (pr) => pr.id && pr.removed === false && pr.edited === false
            ),
            ...vals,
            ...vals1,
          ].sort((a, b) => b.rowid - a.rowid);
          setBaustellenarr([...tmparr]);
        }
      }
    },
    [baustellenrarr]
  );

  const onRenderBaustelle = useCallback(
    (
      item?: Baustellen & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<Baustellen & { edited: boolean; removed: boolean }>
          item={item as Baustellen & { edited: boolean; removed: boolean }}
          keyOfItem="baustelle"
          onChange={(e, v) => {
            handleBaustelleChange(item, v, index);
            if (item?.id !== null && item?.id !== undefined) {
              handleStateChange(item, "true", index);
            }
          }}
        />
      );
    },
    [handleBaustelleChange, handleStateChange]
  );

  const onRenderBaustellenNr = useCallback(
    (
      item?: Baustellen & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<Baustellen & { edited: boolean; removed: boolean }>
          item={item as Baustellen & { edited: boolean; removed: boolean }}
          keyOfItem="baustellennr"
          onChange={(e, v) => {
            handleBaustellenNrChange(item, v, index);
            if (item?.id !== null && item?.id !== undefined) {
              handleStateChange(item, "true", index);
            }
          }}
        />
      );
    },
    [handleBaustellenNrChange, handleStateChange]
  );

  const onRenderInaktiv = useCallback(
    (
      item?: Baustellen & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <Checkbox
          checked={item?.inaktiv}
          onChange={(e, d) => {
            handleInaktivChange(item, String(d.checked), index);
            handleStateChange(item, "true", index);
          }}
        />
      );
    },
    [handleInaktivChange, handleStateChange]
  );

  const onRenderOrt = useCallback(
    (
      item?: Baustellen & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<Baustellen & { edited: boolean; removed: boolean }>
          item={item as Baustellen & { edited: boolean; removed: boolean }}
          keyOfItem="ort"
          onChange={(e, v) => {
            handleOrtChange(item, v, index);
            if (item?.id !== null && item?.id !== undefined) {
              handleStateChange(item, "true", index);
            }
          }}
        />
      );
    },
    [handleOrtChange, handleStateChange]
  );
  const onRenderBaufuehrer = useCallback(
    (
      item?: Baustellen & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<Baustellen & { edited: boolean; removed: boolean }>
          item={item as Baustellen & { edited: boolean; removed: boolean }}
          keyOfItem="baufuehrer_Nr"
          onChange={(e, v) => {}}
          onFocusGoneConvertingMethode={(str) => {
            let baufr = baufuehrers?.find((pr) => pr.id === Number(str));
            return (baufr?.name ?? "") + " " + (baufr?.vorname ?? "");
          }}
        >
          <ComboBox
            selectedKey={item?.baufuehrer_Nr}
            onChange={(e, o, i, v) => {
              handleBaufuehrerChange(item, String(o?.key ?? 0), index);
              if (item?.id !== null && item?.id !== undefined) {
                handleStateChange(item, "true", index);
              }
            }}
            options={
              baufuehrers?.map<IComboBoxOption>((pr) => ({
                key: pr.id ?? 0,
                text: pr.name + " " + pr.vorname,
              })) ?? []
            }
          />
        </TextBoxColumn>
      );
    },
    [handleBaufuehrerChange, baufuehrers, handleStateChange]
  );

  const onRenderVorarbeiter = useCallback(
    (
      item?: Baustellen & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<Baustellen & { edited: boolean; removed: boolean }>
          item={item as Baustellen & { edited: boolean; removed: boolean }}
          keyOfItem="vorarbeiter_Nr"
          onChange={(e, v) => {}}
          onFocusGoneConvertingMethode={(str) => {
            let baufr = vorarbeiters?.find((pr) => pr.id === Number(str));

            return (baufr?.name ?? "") + " " + (baufr?.vorname ?? "");
          }}
        >
          <ComboBox
            selectedKey={item?.vorarbeiter_Nr}
            onChange={(e, o, i, v) => {
              handleVorarbeiterChange(item, String(o?.key ?? 0), index);
              if (item?.id !== null && item?.id !== undefined) {
                handleStateChange(item, "true", index);
              }
            }}
            options={
              vorarbeiters?.map<IComboBoxOption>((pr) => ({
                key: pr.id ?? 0,
                text: pr.name + " " + pr.vorname,
              })) ?? []
            }
          />
        </TextBoxColumn>
      );
    },
    [handleVorarbeiterChange, vorarbeiters, handleStateChange]
  );

  const onRenderFirma = useCallback(
    (
      item?: Baustellen & { rowid: number; edited: boolean; removed: boolean },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<Baustellen & { edited: boolean; removed: boolean }>
          item={item as Baustellen & { edited: boolean; removed: boolean }}
          keyOfItem="firma_nr"
          onChange={(e, v) => {}}
          onFocusGoneConvertingMethode={(str) => {
            return str === "1" ? "Tozzo" : "Schleith";
          }}
        >
          <ComboBox
            selectedKey={item?.firma_nr === "1" ? "Tozzo" : "Schleith"}
            onChange={(e, o, i, v) => {
              let input = v === "Tozzo" ? "1" : "2";
              handleFirmaChange(item, input, index);
              if (item?.id !== null && item?.id !== undefined) {
                handleStateChange(item, "true", index);
              }
            }}
            options={["Tozzo", "Schleith"].map((pr) => ({
              key: pr,
              text: pr,
            }))}
          />
        </TextBoxColumn>
      );
    },
    [handleFirmaChange, handleStateChange]
  );

  const baustellencolumns: IColumn[] = useMemo(
    () => [
      {
        key: "firma",
        name: "Firma",
        fieldName: "firma",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderFirma,
      },
      {
        key: "baustelle",
        name: "Baustelle",
        fieldName: "baustelle",
        minWidth: 200,
        maxWidth: 400,
        isResizable: true,
        onRender: onRenderBaustelle,
      },
      {
        key: "baustellennr",
        name: "Baustellen Nr",
        fieldName: "baustellennr",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderBaustellenNr,
      },
      {
        key: "ort",
        name: "Ort",
        fieldName: "ort",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderOrt,
      },
      {
        key: "baufuehrer",
        name: "Baufuehrer",
        fieldName: "baufuehrer",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderBaufuehrer,
      },
      {
        key: "vorarbeiter",
        name: "Vorarbeiter",
        fieldName: "vorarbeiter",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderVorarbeiter,
      },
      {
        key: "inaktiv",
        name: "Inaktiv",
        fieldName: "inaktiv",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderInaktiv,
      },
    ],
    [
      onRenderBaustelle,
      onRenderBaustellenNr,
      onRenderOrt,
      onRenderFirma,
      onRenderVorarbeiter,
      onRenderInaktiv,
      onRenderBaufuehrer,
    ]
  );

  return !loading && data ? (
    <Page title="Baustellen">
      <DetailsListEditable<Partial<Baustellen>>
        hasSave
        onSaveClick={() => {
          let updatables =
            baustellenrarr
              ?.filter((pr) => pr.edited && pr.removed === false)
              .filter(
                (valobj) =>
                  valobj.firma_nr !== undefined &&
                  (valobj.baustelle ?? "").length > 0 &&
                  (valobj.baustellennr ?? "").length > 0 &&
                  valobj.ort !== undefined &&
                  valobj.id !== undefined
              )
              .map<BaustellenInput>((pr) => ({
                firma: Number(pr.firma_nr) ?? 0,
                inaktiv: pr.inaktiv === true ? 1 : 0,
                baustellennr: pr.baustellennr ?? "",
                baustelle: pr.baustelle ?? "",
                ort: pr.ort ?? "",
                id: pr.id,
                vorarbeiter: pr.vorarbeiter_Nr,
                baufuehrer: pr.baufuehrer_Nr,
              })) ?? [];
          let removable =
            baustellenrarr
              ?.filter(
                (pr) => pr.removed && pr.id !== undefined && pr.id !== null
              )
              .map((pr) => pr.id ?? 0) ?? [];
          let addable =
            baustellenrarr
              ?.filter((pr) => pr.id === null || pr.id === undefined)
              .filter(
                (valobj) =>
                  valobj.firma_nr !== undefined &&
                  (valobj.baustelle ?? "").length > 0 &&
                  (valobj.baustellennr ?? "").length > 0 &&
                  valobj.ort !== undefined
              )
              .map<BaustellenInput>((pr) => ({
                firma: Number(pr.firma_nr) ?? 0,
                inaktiv: pr.inaktiv === true ? 1 : 0,
                baustellennr: pr.baustellennr ?? "",
                baustelle: pr.baustelle ?? "",
                ort: pr.ort ?? "",
                vorarbeiter: pr.vorarbeiter_Nr,
                baufuehrer: pr.baufuehrer_Nr,
              })) ?? [];
          if (
            updatables.length > 0 ||
            addable.length > 0 ||
            removable.length > 0
          ) {
            save({
              variables: {
                addable: addable,
                updatable: updatables,
                removable: removable,
              },
              onCompleted: handleMutateComplete,
            });
          }
        }}
        data={baustellenrarr?.filter((pr) => pr.removed !== true) ?? []}
        onAdd={() => {
          let tmp = baustellenrarr?.map((pr) => pr.rowid) ?? [];
          let wart: Partial<Baustellen> & {
            rowid: number;
            edited: boolean;
            removed: boolean;
          } = {
            firma_nr: "1",
            baufuehrer_Nr: 12,
            vorarbeiter_Nr: 25,
            rowid: Math.max(...tmp) + 1,
            removed: false,
            edited: false,
          };
          let tmpsorted = [...(baustellenrarr ?? []), wart]?.sort(
            (a, b) => b.rowid - a.rowid
          );
          setBaustellenarr(tmpsorted);
        }}
        onRemoveClick={(selected) => {
          let newbaustellenrarr = baustellenrarr?.filter(
            (pr) => selected?.map((t) => t.rowid).includes(pr.rowid) === false
          );
          newbaustellenrarr = [
            ...(newbaustellenrarr ?? []),
            ...selected
              .filter((a) => a.id !== null && a.id !== undefined)
              .map((k) => ({ ...k, removed: true, edited: false })),
          ];
          if (newbaustellenrarr) {
            setBaustellenarr([...newbaustellenrarr]);
          }
        }}
        columns={baustellencolumns}
      />
    </Page>
  ) : (
    <div
      style={{
        height: "85vh",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Spinner />
    </div>
  );
}
