import { useEffect, useCallback } from "react";
import {
  Belagbestellung,
  GetBestellungQuery,
  Ladeliste,
  Maybe,
} from "../../gql/graphql";
import {
  EinmaumaschieneType,
  IBestellungFormProps,
  getMaschienen,
} from "../BestellungForm";
function parseDuration(duration: string) {
  const regex = /PT(\d+H)?(\d+M)?/;
  const matches = duration.match(regex);
  if (matches && matches !== null) {
    const hours = matches[1] ? parseInt(matches[1].replace("H", "")) : 0;
    const minutes = matches[2] ? parseInt(matches[2].replace("M", "")) : 0;
    return { hours, minutes };
  }
  return { hours: 0, minutes: 0 };
}

export function durationToTime(duration?: string) {
  if (duration) {
    if (duration.includes(":") && duration.length === 5) {
      return duration;
    } else if (duration.includes(":")) {
      let arrtmp = duration.split(":");
      let Hourtime = arrtmp[0].length === 2 ? arrtmp[0] : "0" + arrtmp[0];
      let MinuteTime = arrtmp[1].length === 2 ? arrtmp[1] : arrtmp[1] + "0";
      return Hourtime + ":" + MinuteTime;
    } else {
      const { hours, minutes } = parseDuration(duration);
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      return date.toTimeString().slice(0, 5); // Returns time in HH:MM format
    }
  } else {
    return "00:00";
  }
}

function useInitializeData(initializeArgs: {
  data?: GetBestellungQuery;
  einbaumaschienen?: EinmaumaschieneType[];
  setEinbaumaschienen: React.Dispatch<
    React.SetStateAction<EinmaumaschieneType[] | undefined>
  >;
  setBelagsliste: React.Dispatch<
    React.SetStateAction<Belagbestellung & { rowid: number }[]>
  >;
  setLliste: React.Dispatch<
    React.SetStateAction<Ladeliste & { rowid: number }[]>
  >;
  setBaustellenverantwortlicher: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
  setBaufuehrerstate: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
  setEinbaudatum: React.Dispatch<React.SetStateAction<string | undefined>>;
  setEinbaubeginn: React.Dispatch<React.SetStateAction<string | undefined>>;
  setStv: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  setPrepared: React.Dispatch<React.SetStateAction<boolean>>;
  belagsliste: (Belagbestellung & { rowid: number })[];
  loading?: boolean;
  baufuehrer_name?: Maybe<string>;
  props: IBestellungFormProps;
  prepared: boolean;
  lliste: (Ladeliste & { rowid: number })[];
  einbaudatum?: string;
  einbaubeginn?: string;
  stv?: string | null;
}) {
  const {
    data,
    stv,
    setStv,
    setBelagsliste,
    setLliste,
    setBaustellenverantwortlicher,
    setBaufuehrerstate,
    setEinbaudatum,
    einbaudatum,
    einbaubeginn,
    setEinbaubeginn,
    setPrepared,
    props,
    prepared,
    lliste,
    loading,
    belagsliste,
    einbaumaschienen,
    setEinbaumaschienen,
  } = initializeArgs;

  const initializeData = useCallback(() => {
    if (data?.bestellungData && !einbaumaschienen) {
      let {
        ebm_1300,
        em_1600,
        em_1900,
        em_Fremd,
        em_Keine,
        em_Sb_110,
        em_Sb_300,
      } = data.bestellungData;
      let maschienenlist = getMaschienen(
        ebm_1300,
        em_1600,
        em_1900,
        em_Fremd,
        em_Keine,
        em_Sb_110,
        em_Sb_300
      );
      if (maschienenlist) {
        setEinbaumaschienen([...maschienenlist]);
      }
    }
    if (!einbaudatum && data?.bestellungData.datum) {
      setEinbaudatum(
        new Date(props.datum ?? data?.bestellungData.datum).toString()
      );
    }
    if (!einbaubeginn && data?.bestellungData.einbaubegin) {
      setEinbaubeginn(
        durationToTime(props.einbaubegin ?? data?.bestellungData.einbaubegin)
      );
    }
    if (
      !loading &&
      data &&
      data.bestellungData &&
      belagsliste?.length === 0 &&
      lliste?.length === 0 &&
      prepared === false
    ) {
      setPrepared(true);
      setStv(
        props.stv && props.stv !== null ? props.stv : data.bestellungData.stv
      );
      setBelagsliste(
        data.bestellungData.belagbestellung?.map((pr, i) => ({
          rowid: i,
          ...pr,
        })) ?? []
      );
      setLliste(
        data.bestellungData.ladeliste?.map((pr, i) => ({ rowid: i, ...pr })) ??
          []
      );
      setBaustellenverantwortlicher(
        props.baustellenverantwortlicher ??
          data.bestellungData.baustellenverantwortlicher
      );
      setBaufuehrerstate(
        props.baufuehrer_name ?? data.bestellungData.baufuehrer_name
      );
    }
  }, [
    belagsliste?.length,
    data,
    einbaubeginn,
    einbaudatum,
    lliste?.length,
    loading,
    prepared,
    props.baufuehrer_name,
    props.baustellenverantwortlicher,
    props.datum,
    props.einbaubegin,
    setBaufuehrerstate,
    setBaustellenverantwortlicher,
    setBelagsliste,
    setEinbaubeginn,
    setEinbaudatum,
    setLliste,
    setPrepared,
    setEinbaumaschienen,
    einbaumaschienen,
  ]);

  useEffect(() => {
    if (data) {
      initializeData();
    }
  }, [data, initializeData]);
}

export default useInitializeData;
