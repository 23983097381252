import { useMemo } from "react";
import {
  Belagbestellung,
  BelagbestellungInput,
  BestellungInput,
  GetBestellungOptionsQuery,
  GetBestellungQuery,
  Ladeliste,
  LadelisteInput,
} from "../../gql/graphql";
import { EinmaumaschieneType, IBestellungFormProps } from "../BestellungForm";
import { durationToTime } from "./useInitializeFormData";

export function useFormData(
  initializeArgs: {
    data?: GetBestellungQuery;
    optionsData?: GetBestellungOptionsQuery;
    belagsliste: (Belagbestellung & { rowid: number })[];
    props: IBestellungFormProps;
    lliste: (Ladeliste & { rowid: number })[];
    einbaudatum?: string;
    einbaubeginn?: string;
    mischwerk?: string | null;
    baufuehrerstate?: string | null;
    baustellenverantwortlicher?: string | null;
    baustelle?: string | null;
    storniert?: number | null;
    freigegeben?: number | null;
    definitiv?: number | null;
    disponiert?: number | null;
    bemerkung?: string;
    stv?:string|null;
    etappe?: string | null;
    bauteil?: string | null;
    einbaumaschienen?: EinmaumaschieneType[];
  },
  deps: React.DependencyList
) {
  return useMemo(() => {
    const {
      data,
      optionsData,
      baustelle,
      props,
      belagsliste,
      lliste,
      einbaubeginn,
      baustellenverantwortlicher,
      einbaudatum,
      baufuehrerstate,
      mischwerk,
      storniert,
      stv,
      freigegeben,
      definitiv,
      disponiert,
      bemerkung,
      bauteil,
      etappe,
      einbaumaschienen,
    } = initializeArgs;
    let baufr = optionsData?.baufuehrer.find((mitarbeiter) => {
      let searchname = mitarbeiter.vorname + " " + mitarbeiter.name;
      return (
        searchname === (baufuehrerstate ?? data?.bestellungData.baufuehrer_name)
      );
    })?.id;
    let vorarb = optionsData?.vorarbeiter.find(
      (mitarbeiter) =>
        mitarbeiter.vorname + " " + mitarbeiter.name ===
        (baustellenverantwortlicher ??
          data?.bestellungData.baustellenverantwortlicher)
    )?.id;
    let baust = optionsData?.baustellen.find(
      (baustellepr) => baustellepr.baustelle_Name === baustelle
    )?.id;
    if (baust === undefined) {
      baust = optionsData?.baustellen.find(
        (baustellepr) => baustellepr.baustellennr === baustelle?.split(" ")[0]
      )?.id;
    }

    let belaglisteInput = (belagsliste ?? [])
      .map<BelagbestellungInput | undefined>((em) => {
        let { belag, geplante_Menge, wann } = em;
        if (belag && geplante_Menge && wann) {
          return {
            belag: belag,
            geplante_Menge: geplante_Menge,
            wann: wann,
            belagssorte: em.belagssorte,
            bemerkung: em.bemerkung,
            bindemittel: em.bindemittel,
            bSconcatName: em.bSconcatName,
            bSindex: em.bSindex,
            erstPruefFile: em.erstPruefFile,
            id: em.id,
            lLmenge: em.lLmenge,
            rc_Anteil: em.rc_Anteil,
            typ: em.typ,
            zusatz: em.zusatz,
          };
        } else {
          return undefined;
        }
      })
      .filter((pr) => pr?.belag !== undefined) as Array<BelagbestellungInput>;
    let ladelisteInput = (lliste ?? [])
      .map<LadelisteInput | undefined>((em) => {
        let { ladezeit, lkw_art_nr, transport, lkw_Plus_Fuhre } = em;
        let belagLL = belagsliste.find((pr) => {
          let sorte = pr.belagssorte ? pr.belagssorte + " " : "";
          let typ = pr.typ ? pr.typ + " " : "";
          let binder = pr.bindemittel ? pr.bindemittel + " " : "";
          let rc = pr.rc_Anteil ? "RC " + pr.rc_Anteil : "";
          let belagsortestr = sorte + typ + binder + rc;
          return belagsortestr === em.belags_Sorte;
        })?.belag;

        if (ladezeit && lkw_art_nr && transport && lkw_Plus_Fuhre && belagLL) {
          return {
            menge: Number(em.menge),
            ladezeit,
            lkw_art_nr: lkw_art_nr,
            transport: transport,
            lkw_Plus_Fuhre: em.lkw_Plus_Fuhre,
            lkw: Number(("" + lkw_Plus_Fuhre).split(".")[0]),
            belag: belagLL,
            belags_Sorte: em.belags_Sorte,
            bSindex: em.bSindex,
            id: em.id,
          };
        } else {
          return undefined;
        }
      })
      .filter((pr) => pr) as Array<LadelisteInput>;

    if (baufr && vorarb && baust && einbaubeginn && mischwerk) {
      let input: BestellungInput = {
        bauteil,
        etappe,
        bemerkung,
        baufuehrer: baufr,
        datum: einbaudatum ? new Date(einbaudatum).toISOString() : undefined,
        baustelle: baust,
        einbaubegin:
          einbaubeginn && String(einbaubeginn ?? "").includes(":") === false
            ? durationToTime(einbaubeginn)
            : einbaubeginn,
        mischwerk,
        stv,
        id: props.bid,
        vorarbeiter: vorarb,
        ladeliste: ladelisteInput,
        belagbestellungen: belaglisteInput,
        bestaetigt: disponiert ? true : false,
        bestellt: definitiv ? true : false,
        freigegeben: freigegeben ? true : false,
        storniert: storniert ? true : false,
        em_fremd: einbaumaschienen?.find((pr) => pr === "EM Fremd")
          ? true
          : false,
        em_1600: einbaumaschienen?.find(
          (pr) => pr === "EM Vögele 1800 (2.70 - 5.00 / 6.50) 2."
        )
          ? true
          : false,
        em_sb_300: einbaumaschienen?.find(
          (pr) => pr === "EM 1900 - 2 (3.20 - 6.00 / 7.50 - 9.00)"
        )
          ? true
          : false,
        em_keine: einbaumaschienen?.find((pr) => pr === "Keine") ? true : false,
        ebm_1300: einbaumaschienen?.find(
          (pr) => pr === "EBM 1300 - 3 Raupen (2.00 - 3.40)"
        )
          ? true
          : false,
        em_sb_110: einbaumaschienen?.find(
          (pr) => pr === "EM Super Boy (ACT: 1.10 - 2.00/2.50)"
        )
          ? true
          : false,
        em_1900: einbaumaschienen?.find(
          (pr) => pr === "EM Vögele 1800 (2.70 - 5.00 / 6.50)"
        )
          ? true
          : false,
      };
      return input;
    }
  }, [initializeArgs]);
}
