import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: { input: any; output: any; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: any; output: any; }
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Baustellen = {
  __typename?: 'Baustellen';
  baufuehrer?: Maybe<Scalars['String']['output']>;
  baufuehrer_Nr: Scalars['Int']['output'];
  baustelle: Scalars['String']['output'];
  baustelle_Name: Scalars['String']['output'];
  baustellennr: Scalars['String']['output'];
  eingetragen: Scalars['DateTime']['output'];
  ersteller?: Maybe<Scalars['String']['output']>;
  firma: Scalars['String']['output'];
  firma_nr: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  inaktiv: Scalars['Boolean']['output'];
  ort: Scalars['String']['output'];
  vorarbeiter?: Maybe<Scalars['String']['output']>;
  vorarbeiter_Nr: Scalars['Int']['output'];
};

export type BaustellenInput = {
  baufuehrer?: InputMaybe<Scalars['Int']['input']>;
  baustelle: Scalars['String']['input'];
  baustellennr: Scalars['String']['input'];
  firma: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  inaktiv?: InputMaybe<Scalars['Int']['input']>;
  ort: Scalars['String']['input'];
  vorarbeiter?: InputMaybe<Scalars['Int']['input']>;
};

export type BaustellenMutations = {
  __typename?: 'BaustellenMutations';
  del: Scalars['Boolean']['output'];
  insert: Array<Baustellen>;
  save: MutationSaveOutputOfBaustellen;
  update: Baustellen;
};


export type BaustellenMutationsDelArgs = {
  id: Scalars['Int']['input'];
};


export type BaustellenMutationsInsertArgs = {
  baustellens: Array<BaustellenInput>;
};


export type BaustellenMutationsSaveArgs = {
  addBaustellen: Array<BaustellenInput>;
  delBaustellen: Array<Scalars['Int']['input']>;
  updateBaustellen: Array<BaustellenInput>;
};


export type BaustellenMutationsUpdateArgs = {
  baustellen: BaustellenInput;
};

export type Belag = {
  __typename?: 'Belag';
  bSbinder?: Maybe<Scalars['String']['output']>;
  bSconcatName?: Maybe<Scalars['String']['output']>;
  bSrcp?: Maybe<Scalars['String']['output']>;
  bSsorte?: Maybe<Scalars['String']['output']>;
  bStyp?: Maybe<Scalars['String']['output']>;
  bSzusatz?: Maybe<Scalars['String']['output']>;
  bindemittel: Scalars['String']['output'];
  bm: Scalars['Float']['output'];
  eingetragen: Scalars['DateTime']['output'];
  erstPruefFile?: Maybe<Scalars['String']['output']>;
  ersteller: Scalars['String']['output'];
  ersteller_nr: Scalars['Int']['output'];
  firma: Scalars['Int']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  inaktiv: Scalars['Boolean']['output'];
  sorte: Scalars['String']['output'];
};

export type BelagInput = {
  bSbinder?: InputMaybe<Scalars['String']['input']>;
  bSconcatName?: InputMaybe<Scalars['String']['input']>;
  bSrcp?: InputMaybe<Scalars['String']['input']>;
  bSsorte?: InputMaybe<Scalars['String']['input']>;
  bStyp?: InputMaybe<Scalars['String']['input']>;
  bSzusatz?: InputMaybe<Scalars['String']['input']>;
  bindemittel: Scalars['String']['input'];
  bm: Scalars['Float']['input'];
  erstPruefFile?: InputMaybe<Scalars['String']['input']>;
  firma: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  inaktiv?: InputMaybe<Scalars['Boolean']['input']>;
  sorte: Scalars['String']['input'];
};

export type BelagMutations = {
  __typename?: 'BelagMutations';
  del: Scalars['Boolean']['output'];
  save: MutationSaveOutputOfBelag;
  update: Belag;
  uploadPruefFile: Scalars['String']['output'];
};


export type BelagMutationsDelArgs = {
  id: Scalars['Int']['input'];
};


export type BelagMutationsSaveArgs = {
  addbelag: Array<BelagInput>;
  delBelag: Array<Scalars['Int']['input']>;
  updatebelag: Array<BelagInput>;
};


export type BelagMutationsUpdateArgs = {
  belag: BelagInput;
};


export type BelagMutationsUploadPruefFileArgs = {
  belagid: Scalars['Int']['input'];
  filename: Scalars['String']['input'];
  input: Scalars['String']['input'];
};

export type Belagbestellung = {
  __typename?: 'Belagbestellung';
  bSconcatName?: Maybe<Scalars['String']['output']>;
  bSindex?: Maybe<Scalars['Int']['output']>;
  belag?: Maybe<Scalars['Int']['output']>;
  belagssorte?: Maybe<Scalars['String']['output']>;
  bemerkung?: Maybe<Scalars['String']['output']>;
  bestellDatum?: Maybe<Scalars['DateTime']['output']>;
  bestellid?: Maybe<Scalars['Int']['output']>;
  bindemittel?: Maybe<Scalars['String']['output']>;
  erstPruefFile?: Maybe<Scalars['String']['output']>;
  geplante_Menge?: Maybe<Scalars['Decimal']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lLmenge?: Maybe<Scalars['Decimal']['output']>;
  ladezeitMin?: Maybe<Scalars['String']['output']>;
  rc_Anteil?: Maybe<Scalars['String']['output']>;
  typ?: Maybe<Scalars['String']['output']>;
  wann?: Maybe<Scalars['Int']['output']>;
  zusatz?: Maybe<Scalars['String']['output']>;
};

export type BelagbestellungInput = {
  bSconcatName?: InputMaybe<Scalars['String']['input']>;
  bSindex?: InputMaybe<Scalars['Int']['input']>;
  belag: Scalars['Int']['input'];
  belagssorte?: InputMaybe<Scalars['String']['input']>;
  bemerkung?: InputMaybe<Scalars['String']['input']>;
  bindemittel?: InputMaybe<Scalars['String']['input']>;
  erstPruefFile?: InputMaybe<Scalars['String']['input']>;
  geplante_Menge: Scalars['Decimal']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  lLmenge?: InputMaybe<Scalars['Decimal']['input']>;
  rc_Anteil?: InputMaybe<Scalars['String']['input']>;
  typ?: InputMaybe<Scalars['String']['input']>;
  wann: Scalars['Int']['input'];
  zusatz?: InputMaybe<Scalars['String']['input']>;
};

export type Benutzer = {
  __typename?: 'Benutzer';
  firma?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  rolle: Scalars['String']['output'];
};

export type Bestellung = {
  __typename?: 'Bestellung';
  baufuehrer?: Maybe<Scalars['Int']['output']>;
  baufuehrer_name?: Maybe<Scalars['String']['output']>;
  baustelle?: Maybe<Scalars['Int']['output']>;
  baustelle_Name?: Maybe<Scalars['String']['output']>;
  baustellenverantwortlicher?: Maybe<Scalars['String']['output']>;
  baustellenverantwortlicher_nr?: Maybe<Scalars['Int']['output']>;
  bauteil?: Maybe<Scalars['String']['output']>;
  belagbestellung?: Maybe<Array<Belagbestellung>>;
  bemerkung?: Maybe<Scalars['String']['output']>;
  benutzername?: Maybe<Scalars['String']['output']>;
  bestaetigt?: Maybe<Scalars['Int']['output']>;
  bestellt?: Maybe<Scalars['Int']['output']>;
  bid: Scalars['Int']['output'];
  datum: Scalars['DateTime']['output'];
  ebm_1300?: Maybe<Scalars['Int']['output']>;
  einbaubegin?: Maybe<Scalars['TimeSpan']['output']>;
  eingetragen?: Maybe<Scalars['DateTime']['output']>;
  em_1600?: Maybe<Scalars['Int']['output']>;
  em_1900?: Maybe<Scalars['Int']['output']>;
  em_Fremd?: Maybe<Scalars['Int']['output']>;
  em_Keine?: Maybe<Scalars['Int']['output']>;
  em_Sb_110?: Maybe<Scalars['Int']['output']>;
  em_Sb_130?: Maybe<Scalars['Int']['output']>;
  em_Sb_300?: Maybe<Scalars['Int']['output']>;
  ersteller?: Maybe<Scalars['String']['output']>;
  etappe?: Maybe<Scalars['String']['output']>;
  freigegeben?: Maybe<Scalars['Int']['output']>;
  gespeichertam?: Maybe<Scalars['DateTime']['output']>;
  ladeliste?: Maybe<Array<Ladeliste>>;
  mischwerk?: Maybe<Scalars['String']['output']>;
  report?: Maybe<Scalars['String']['output']>;
  storniert?: Maybe<Scalars['Int']['output']>;
  stv?: Maybe<Scalars['String']['output']>;
  vorarbeiter?: Maybe<Scalars['Int']['output']>;
};

export type BestellungInput = {
  baufuehrer: Scalars['Int']['input'];
  baustelle: Scalars['Int']['input'];
  bauteil?: InputMaybe<Scalars['String']['input']>;
  belagbestellungen?: InputMaybe<Array<BelagbestellungInput>>;
  bemerkung?: InputMaybe<Scalars['String']['input']>;
  bestaetigt?: Scalars['Boolean']['input'];
  bestellt?: Scalars['Boolean']['input'];
  datum: Scalars['DateTime']['input'];
  ebm_1300?: InputMaybe<Scalars['Boolean']['input']>;
  einbaubegin: Scalars['String']['input'];
  eingetragen?: InputMaybe<Scalars['DateTime']['input']>;
  em_1600?: InputMaybe<Scalars['Boolean']['input']>;
  em_1900?: InputMaybe<Scalars['Boolean']['input']>;
  em_fremd?: InputMaybe<Scalars['Boolean']['input']>;
  em_keine?: InputMaybe<Scalars['Boolean']['input']>;
  em_sb_110?: InputMaybe<Scalars['Boolean']['input']>;
  em_sb_130?: InputMaybe<Scalars['Boolean']['input']>;
  em_sb_300?: InputMaybe<Scalars['Boolean']['input']>;
  etappe?: InputMaybe<Scalars['String']['input']>;
  freigegeben?: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  importdatei?: InputMaybe<Scalars['String']['input']>;
  ladeliste?: InputMaybe<Array<LadelisteInput>>;
  mischwerk: Scalars['String']['input'];
  storniert?: Scalars['Boolean']['input'];
  stv?: InputMaybe<Scalars['String']['input']>;
  vorarbeiter: Scalars['Int']['input'];
};

export type BestellungMutations = {
  __typename?: 'BestellungMutations';
  del: Scalars['Boolean']['output'];
  kopie: Bestellung;
  save: Bestellung;
  stornieren: Bestellung;
};


export type BestellungMutationsDelArgs = {
  id: Scalars['Int']['input'];
};


export type BestellungMutationsKopieArgs = {
  id: Scalars['Int']['input'];
};


export type BestellungMutationsSaveArgs = {
  bestellung: BestellungInput;
};


export type BestellungMutationsStornierenArgs = {
  id: Scalars['Int']['input'];
};

export type DokumentMutations = {
  __typename?: 'DokumentMutations';
  addDocument?: Maybe<Dokumente>;
  removeDocuments: Scalars['Boolean']['output'];
};


export type DokumentMutationsAddDocumentArgs = {
  desc: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  input: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type DokumentMutationsRemoveDocumentsArgs = {
  ids: Array<Scalars['Int']['input']>;
};

export type Dokumente = {
  __typename?: 'Dokumente';
  description_text: Scalars['String']['output'];
  file_name: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type Ladeliste = {
  __typename?: 'Ladeliste';
  bSindex?: Maybe<Scalars['Int']['output']>;
  belag?: Maybe<Scalars['Int']['output']>;
  belags_Sorte?: Maybe<Scalars['String']['output']>;
  bestellid?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  ladezeit?: Maybe<Scalars['String']['output']>;
  lkw?: Maybe<Scalars['Int']['output']>;
  lkw_Art?: Maybe<Scalars['String']['output']>;
  lkw_Plus_Fuhre?: Maybe<Scalars['Decimal']['output']>;
  lkw_art_nr?: Maybe<Scalars['Int']['output']>;
  menge?: Maybe<Scalars['Decimal']['output']>;
  transport?: Maybe<Scalars['String']['output']>;
};

export type LadelisteInput = {
  bSindex?: InputMaybe<Scalars['Int']['input']>;
  belag: Scalars['Int']['input'];
  belags_Sorte?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ladezeit: Scalars['String']['input'];
  lkw: Scalars['Int']['input'];
  lkw_Plus_Fuhre: Scalars['Decimal']['input'];
  lkw_art_nr: Scalars['Int']['input'];
  menge: Scalars['Decimal']['input'];
  transport: Scalars['String']['input'];
};

export type LogBestellung = {
  __typename?: 'LogBestellung';
  aktion?: Maybe<Scalars['String']['output']>;
  benutzer?: Maybe<Scalars['String']['output']>;
  benutzername?: Maybe<Scalars['String']['output']>;
  bestellid?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  vorname?: Maybe<Scalars['String']['output']>;
  zeit?: Maybe<Scalars['DateTime']['output']>;
  zeitpunkt?: Maybe<Scalars['DateTime']['output']>;
};

export type Mitarbeiter = {
  __typename?: 'Mitarbeiter';
  eingetragen: Scalars['DateTime']['output'];
  ersteller?: Maybe<Scalars['String']['output']>;
  firma: Scalars['Int']['output'];
  funktion: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  inaktiv: Scalars['Int']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  personalnr: Scalars['String']['output'];
  vorname: Scalars['String']['output'];
};

export type MitarbeiterInput = {
  firma: Scalars['Int']['input'];
  funktion: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  inaktiv: Scalars['Int']['input'];
  mobile?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  personalnr?: InputMaybe<Scalars['String']['input']>;
  vorname: Scalars['String']['input'];
};

export type MitarbeiterMutations = {
  __typename?: 'MitarbeiterMutations';
  del: Scalars['Boolean']['output'];
  insert: Array<Mitarbeiter>;
  save: MutationSaveOutputOfMitarbeiter;
  update: Mitarbeiter;
};


export type MitarbeiterMutationsDelArgs = {
  id: Scalars['Int']['input'];
};


export type MitarbeiterMutationsInsertArgs = {
  mitarbeiter: Array<MitarbeiterInput>;
};


export type MitarbeiterMutationsSaveArgs = {
  addmitarbeiter: Array<MitarbeiterInput>;
  delMitarbeiter: Array<Scalars['Int']['input']>;
  updatemitarbeiter: Array<MitarbeiterInput>;
};


export type MitarbeiterMutationsUpdateArgs = {
  mitarbeiter: MitarbeiterInput;
};

export type Mutation = {
  __typename?: 'Mutation';
  baustellen: BaustellenMutations;
  belag: BelagMutations;
  bestellung: BestellungMutations;
  dokumente: DokumentMutations;
  mitarbeiter: MitarbeiterMutations;
  news: NewsMutation;
  wartung: WartungMutations;
};

export type MutationSaveOutputOfBaustellen = {
  __typename?: 'MutationSaveOutputOfBaustellen';
  added?: Maybe<Array<Baustellen>>;
  removed?: Maybe<Array<Scalars['Int']['output']>>;
  updated?: Maybe<Array<Baustellen>>;
};

export type MutationSaveOutputOfBelag = {
  __typename?: 'MutationSaveOutputOfBelag';
  added?: Maybe<Array<Belag>>;
  removed?: Maybe<Array<Scalars['Int']['output']>>;
  updated?: Maybe<Array<Belag>>;
};

export type MutationSaveOutputOfMitarbeiter = {
  __typename?: 'MutationSaveOutputOfMitarbeiter';
  added?: Maybe<Array<Mitarbeiter>>;
  removed?: Maybe<Array<Scalars['Int']['output']>>;
  updated?: Maybe<Array<Mitarbeiter>>;
};

export type News = {
  __typename?: 'News';
  datum: Scalars['DateTime']['output'];
  ersteller: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  titel: Scalars['String']['output'];
};

export type NewsInput = {
  datum: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
  titel: Scalars['String']['input'];
};

export type NewsMutation = {
  __typename?: 'NewsMutation';
  addNews: News;
  del: Scalars['Boolean']['output'];
  update: News;
};


export type NewsMutationAddNewsArgs = {
  news: NewsInput;
};


export type NewsMutationDelArgs = {
  id: Scalars['Int']['input'];
};


export type NewsMutationUpdateArgs = {
  news: NewsInput;
};

export type Query = {
  __typename?: 'Query';
  baustellen: Array<Baustellen>;
  belag: Array<Belag>;
  belagPdf?: Maybe<Array<Scalars['Byte']['output']>>;
  belagbestellung: Belagbestellung;
  belagsbestellungPdf?: Maybe<Array<Scalars['Byte']['output']>>;
  benutzeDataStv: Array<Benutzer>;
  benutzer: Benutzer;
  benutzerDatasec: Array<Benutzer>;
  bestellungData: Bestellung;
  bestellungDataAll: Array<Bestellung>;
  bestellungDataArchiv: Array<Bestellung>;
  bestellungDataOpen: Array<Belagbestellung>;
  dispo: Array<Bestellung>;
  dokumentPdf?: Maybe<Array<Scalars['Byte']['output']>>;
  dokumente: Array<Dokumente>;
  ladeliste: Ladeliste;
  ladelisteDataAll: Array<Ladeliste>;
  meInfos: User;
  mitarbeiter: Array<Mitarbeiter>;
  news: Array<News>;
  report: ReportQuery;
  reportPdf?: Maybe<Array<Scalars['Byte']['output']>>;
  typingOptions: Array<TypingsOptions>;
  wartung: Array<Wartung>;
};


export type QueryBaustellenArgs = {
  inaktiv?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBelagArgs = {
  inaktiv?: Scalars['Boolean']['input'];
};


export type QueryBelagPdfArgs = {
  id: Scalars['Int']['input'];
};


export type QueryBelagbestellungArgs = {
  id: Scalars['String']['input'];
};


export type QueryBelagsbestellungPdfArgs = {
  path: Scalars['String']['input'];
};


export type QueryBestellungDataArgs = {
  bid: Scalars['Int']['input'];
};


export type QueryBestellungDataAllArgs = {
  bestellungs_datumprod?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryBestellungDataArchivArgs = {
  freigabe?: InputMaybe<Scalars['Boolean']['input']>;
  jahr?: InputMaybe<Scalars['Int']['input']>;
  monat?: InputMaybe<Scalars['Int']['input']>;
  nurmeine?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBestellungDataOpenArgs = {
  datumprod?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryDispoArgs = {
  freigabe: Scalars['Boolean']['input'];
};


export type QueryDokumentPdfArgs = {
  path: Scalars['String']['input'];
};


export type QueryMitarbeiterArgs = {
  funktion?: InputMaybe<Scalars['Int']['input']>;
  inaktiv?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryNewsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReportPdfArgs = {
  path: Scalars['String']['input'];
};


export type QueryTypingOptionsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  typename?: InputMaybe<Scalars['String']['input']>;
};

export type Report = {
  __typename?: 'Report';
  anzFuhren: Scalars['Int']['output'];
  anzLkw: Scalars['Int']['output'];
  baufuehrer: Scalars['String']['output'];
  baustelle: Scalars['String']['output'];
  belag?: Maybe<Array<ReportBelagsBestellung>>;
  bemerkung: Scalars['String']['output'];
  bestellungID: Scalars['Int']['output'];
  einbaudatum: Scalars['String']['output'];
  em: Scalars['String']['output'];
  etappeBauteil: Scalars['String']['output'];
  id: Scalars['String']['output'];
  ladeliste?: Maybe<Array<ReportLadeliste>>;
  log: Array<LogBestellung>;
  mischwerk: Scalars['String']['output'];
  status: Scalars['String']['output'];
  storniert: Scalars['Int']['output'];
  vorarbeiter: Scalars['String']['output'];
};

export type ReportBelagsBestellung = {
  __typename?: 'ReportBelagsBestellung';
  altBindemittel?: Maybe<Scalars['String']['output']>;
  altSorte?: Maybe<Scalars['String']['output']>;
  bSbemerk?: Maybe<Scalars['String']['output']>;
  bSbinder?: Maybe<Scalars['String']['output']>;
  bSconcatName?: Maybe<Scalars['String']['output']>;
  bSindex?: Maybe<Scalars['String']['output']>;
  bSrcp?: Maybe<Scalars['String']['output']>;
  bSsorte?: Maybe<Scalars['String']['output']>;
  bStyp?: Maybe<Scalars['String']['output']>;
  bSzusatz?: Maybe<Scalars['String']['output']>;
  belag?: Maybe<Scalars['Int']['output']>;
  bestellid?: Maybe<Scalars['Int']['output']>;
  erstPruefFile?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lLmenge?: Maybe<Scalars['Int']['output']>;
  menge?: Maybe<Scalars['Int']['output']>;
  wann?: Maybe<Scalars['Int']['output']>;
};

export type ReportLadeliste = {
  __typename?: 'ReportLadeliste';
  altBindemittel?: Maybe<Scalars['String']['output']>;
  altSorte?: Maybe<Scalars['String']['output']>;
  bSconcatName: Scalars['String']['output'];
  bSindex: Scalars['Int']['output'];
  belag: Scalars['Int']['output'];
  bestellid: Scalars['Int']['output'];
  fuhre: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  ladezeit: Scalars['String']['output'];
  lkw: Scalars['Int']['output'];
  lkw_art: Scalars['String']['output'];
  menge: Scalars['Int']['output'];
  transport?: Maybe<Scalars['String']['output']>;
};

export type ReportMutations = {
  __typename?: 'ReportMutations';
  createReport: Scalars['String']['output'];
  pdfBestellung: Scalars['Boolean']['output'];
  sendPDFtoBaufuehrer: Scalars['Boolean']['output'];
};


export type ReportMutationsCreateReportArgs = {
  bid: Scalars['Int']['input'];
  storagename?: InputMaybe<Scalars['String']['input']>;
};


export type ReportMutationsPdfBestellungArgs = {
  bestellungID: Scalars['String']['input'];
  saveToDisk: Scalars['Boolean']['input'];
};


export type ReportMutationsSendPdFtoBaufuehrerArgs = {
  bestellungID: Scalars['String']['input'];
};

export type ReportQuery = {
  __typename?: 'ReportQuery';
  belagBestellungReport: Report;
};


export type ReportQueryBelagBestellungReportArgs = {
  bid: Scalars['Int']['input'];
};

export type TypingsOptions = {
  __typename?: 'TypingsOptions';
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  option_nr?: Maybe<Scalars['Int']['output']>;
  option_value?: Maybe<Scalars['String']['output']>;
  typings_id?: Maybe<Scalars['Int']['output']>;
};

export type User = {
  __typename?: 'User';
  firma: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  upn: Scalars['String']['output'];
};

export type Wartung = {
  __typename?: 'Wartung';
  bis: Scalars['DateTime']['output'];
  bistime?: Maybe<Scalars['TimeSpan']['output']>;
  eingetragen?: Maybe<Scalars['String']['output']>;
  ersteller: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  typ: Scalars['String']['output'];
  von: Scalars['DateTime']['output'];
  vontime?: Maybe<Scalars['TimeSpan']['output']>;
};

export type WartungInput = {
  bis: Scalars['DateTime']['input'];
  bistime?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  typ: Scalars['String']['input'];
  von: Scalars['DateTime']['input'];
  vontime?: InputMaybe<Scalars['String']['input']>;
};

export type WartungMutations = {
  __typename?: 'WartungMutations';
  del: Scalars['Boolean']['output'];
  save: Array<Wartung>;
  update: Wartung;
};


export type WartungMutationsDelArgs = {
  id: Scalars['Int']['input'];
};


export type WartungMutationsSaveArgs = {
  rows: Array<WartungInput>;
};


export type WartungMutationsUpdateArgs = {
  wartung: WartungInput;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', meInfos: { __typename?: 'User', firma: string, id?: string | null, role: string, upn: string } };

export type GetBaustellenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBaustellenQuery = { __typename?: 'Query', baustellen: Array<{ __typename?: 'Baustellen', baufuehrer?: string | null, baufuehrer_Nr: number, baustelle: string, baustelle_Name: string, baustellennr: string, eingetragen: any, ersteller?: string | null, firma: string, firma_nr: string, id: number, inaktiv: boolean, ort: string, vorarbeiter?: string | null, vorarbeiter_Nr: number }>, mitarbeiter: Array<{ __typename?: 'Mitarbeiter', id?: number | null, name: string, vorname: string, funktion: string }> };

export type GetMitarbeiterQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMitarbeiterQuery = { __typename?: 'Query', mitarbeiter: Array<{ __typename?: 'Mitarbeiter', firma: number, eingetragen: any, ersteller?: string | null, funktion: string, id?: number | null, inaktiv: number, mobile?: string | null, name: string, personalnr: string, vorname: string }> };

export type GetNewsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNewsQuery = { __typename?: 'Query', news: Array<{ __typename?: 'News', id: number, titel: string, ersteller: string, text: string, datum: any }> };

export type SaveBaustellenMutationVariables = Exact<{
  addable: Array<BaustellenInput> | BaustellenInput;
  updatable: Array<BaustellenInput> | BaustellenInput;
  removable: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type SaveBaustellenMutation = { __typename?: 'Mutation', baustellen: { __typename?: 'BaustellenMutations', save: { __typename?: 'MutationSaveOutputOfBaustellen', removed?: Array<number> | null, added?: Array<{ __typename?: 'Baustellen', baufuehrer?: string | null, baufuehrer_Nr: number, baustelle: string, baustelle_Name: string, baustellennr: string, eingetragen: any, ersteller?: string | null, firma: string, firma_nr: string, id: number, inaktiv: boolean, ort: string, vorarbeiter?: string | null, vorarbeiter_Nr: number }> | null, updated?: Array<{ __typename?: 'Baustellen', baufuehrer?: string | null, baufuehrer_Nr: number, baustelle: string, baustelle_Name: string, baustellennr: string, eingetragen: any, ersteller?: string | null, firma: string, firma_nr: string, id: number, inaktiv: boolean, ort: string, vorarbeiter?: string | null, vorarbeiter_Nr: number }> | null } } };

export type SaveMitarbeiterMutationVariables = Exact<{
  addmitarbeiter: Array<MitarbeiterInput> | MitarbeiterInput;
  updatemitarbeiter: Array<MitarbeiterInput> | MitarbeiterInput;
  delMitarbeiter: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type SaveMitarbeiterMutation = { __typename?: 'Mutation', mitarbeiter: { __typename?: 'MitarbeiterMutations', save: { __typename?: 'MutationSaveOutputOfMitarbeiter', removed?: Array<number> | null, added?: Array<{ __typename?: 'Mitarbeiter', eingetragen: any, ersteller?: string | null, firma: number, funktion: string, id?: number | null, inaktiv: number, mobile?: string | null, name: string, personalnr: string, vorname: string }> | null, updated?: Array<{ __typename?: 'Mitarbeiter', eingetragen: any, ersteller?: string | null, firma: number, funktion: string, id?: number | null, inaktiv: number, mobile?: string | null, name: string, personalnr: string, vorname: string }> | null } } };

export type AddNewsMutationVariables = Exact<{
  news: NewsInput;
}>;


export type AddNewsMutation = { __typename?: 'Mutation', news: { __typename?: 'NewsMutation', addNews: { __typename?: 'News', datum: any, ersteller: string, id: number, text: string, titel: string } } };

export type RemoveNewsMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type RemoveNewsMutation = { __typename?: 'Mutation', news: { __typename?: 'NewsMutation', del: boolean } };

export type UpdateNewsMutationVariables = Exact<{
  news: NewsInput;
}>;


export type UpdateNewsMutation = { __typename?: 'Mutation', news: { __typename?: 'NewsMutation', update: { __typename?: 'News', id: number, titel: string, text: string, datum: any } } };

export type DeleteBestellungMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteBestellungMutation = { __typename?: 'Mutation', bestellung: { __typename?: 'BestellungMutations', del: boolean } };

export type GetBelagQueryVariables = Exact<{
  inaktiv?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetBelagQuery = { __typename?: 'Query', belag: Array<{ __typename?: 'Belag', bindemittel: string, bm: number, bSbinder?: string | null, bSconcatName?: string | null, bSrcp?: string | null, bSsorte?: string | null, bStyp?: string | null, bSzusatz?: string | null, eingetragen: any, ersteller: string, ersteller_nr: number, erstPruefFile?: string | null, firma: number, id?: number | null, inaktiv: boolean, sorte: string }> };

export type GetBelagOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBelagOptionsQuery = { __typename?: 'Query', Recpercent: Array<{ __typename?: 'TypingsOptions', option_value?: string | null }>, Binder: Array<{ __typename?: 'TypingsOptions', option_value?: string | null }>, Belagssorte: Array<{ __typename?: 'TypingsOptions', option_value?: string | null }>, BSTyp: Array<{ __typename?: 'TypingsOptions', option_value?: string | null }>, BSZusatz: Array<{ __typename?: 'TypingsOptions', option_value?: string | null }>, Wann: Array<{ __typename?: 'TypingsOptions', option_value?: string | null, option_nr?: number | null }> };

export type GetBelagsbestellungPdfQueryVariables = Exact<{
  path: Scalars['String']['input'];
}>;


export type GetBelagsbestellungPdfQuery = { __typename?: 'Query', belagsbestellungPdf?: Array<any> | null };

export type GetBestellArchivQueryVariables = Exact<{
  freigabe?: InputMaybe<Scalars['Boolean']['input']>;
  monat?: InputMaybe<Scalars['Int']['input']>;
  jahr?: InputMaybe<Scalars['Int']['input']>;
  nurmeine?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetBestellArchivQuery = { __typename?: 'Query', bestellungDataArchiv: Array<{ __typename?: 'Bestellung', bid: number, datum: any, eingetragen?: any | null, mischwerk?: string | null, baustelle_Name?: string | null, baustelle?: number | null, einbaubegin?: any | null, bestellt?: number | null, freigegeben?: number | null, storniert?: number | null, bestaetigt?: number | null, report?: string | null, baufuehrer_name?: string | null, ersteller?: string | null }> };

export type GetBestellungQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetBestellungQuery = { __typename?: 'Query', bestellungData: { __typename?: 'Bestellung', stv?: string | null, baufuehrer?: number | null, baufuehrer_name?: string | null, baustelle?: number | null, baustelle_Name?: string | null, baustellenverantwortlicher?: string | null, baustellenverantwortlicher_nr?: number | null, bauteil?: string | null, bemerkung?: string | null, benutzername?: string | null, bestaetigt?: number | null, bestellt?: number | null, bid: number, datum: any, ebm_1300?: number | null, einbaubegin?: any | null, eingetragen?: any | null, em_1600?: number | null, em_1900?: number | null, em_Fremd?: number | null, em_Keine?: number | null, em_Sb_110?: number | null, em_Sb_130?: number | null, em_Sb_300?: number | null, ersteller?: string | null, etappe?: string | null, freigegeben?: number | null, report?: string | null, gespeichertam?: any | null, mischwerk?: string | null, storniert?: number | null, vorarbeiter?: number | null, belagbestellung?: Array<{ __typename?: 'Belagbestellung', belag?: number | null, belagssorte?: string | null, bemerkung?: string | null, bestellDatum?: any | null, bestellid?: number | null, bindemittel?: string | null, bSconcatName?: string | null, bSindex?: number | null, erstPruefFile?: string | null, geplante_Menge?: any | null, id?: number | null, ladezeitMin?: string | null, lLmenge?: any | null, rc_Anteil?: string | null, typ?: string | null, wann?: number | null, zusatz?: string | null }> | null, ladeliste?: Array<{ __typename?: 'Ladeliste', belag?: number | null, belags_Sorte?: string | null, bestellid?: number | null, bSindex?: number | null, id?: number | null, ladezeit?: string | null, lkw?: number | null, lkw_Art?: string | null, lkw_art_nr?: number | null, lkw_Plus_Fuhre?: any | null, menge?: any | null, transport?: string | null }> | null } };

export type GetBestellungOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBestellungOptionsQuery = { __typename?: 'Query', baustellen: Array<{ __typename?: 'Baustellen', baustellennr: string, baustelle_Name: string, id: number }>, vorarbeiter: Array<{ __typename?: 'Mitarbeiter', vorname: string, name: string, id?: number | null, personalnr: string }>, baufuehrer: Array<{ __typename?: 'Mitarbeiter', vorname: string, name: string, id?: number | null, personalnr: string }>, typingOptions: Array<{ __typename?: 'TypingsOptions', option_value?: string | null }> };

export type GetBestellungenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBestellungenQuery = { __typename?: 'Query', bestellungDataAll: Array<{ __typename?: 'Bestellung', bid: number, datum: any, eingetragen?: any | null, mischwerk?: string | null, baustelle_Name?: string | null, baustelle?: number | null, einbaubegin?: any | null, bestellt?: number | null, freigegeben?: number | null, storniert?: number | null, bestaetigt?: number | null, report?: string | null, baufuehrer_name?: string | null }> };

export type GetLadelisteOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLadelisteOptionsQuery = { __typename?: 'Query', LKW: Array<{ __typename?: 'TypingsOptions', option_nr?: number | null, option_value?: string | null }> };

export type GetReportQueryVariables = Exact<{
  filename: Scalars['String']['input'];
}>;


export type GetReportQuery = { __typename?: 'Query', reportPdf?: Array<any> | null };

export type KopieBestellungMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type KopieBestellungMutation = { __typename?: 'Mutation', bestellung: { __typename?: 'BestellungMutations', kopie: { __typename?: 'Bestellung', baufuehrer?: number | null, baufuehrer_name?: string | null, baustelle?: number | null, baustelle_Name?: string | null, baustellenverantwortlicher?: string | null, baustellenverantwortlicher_nr?: number | null, bauteil?: string | null, bemerkung?: string | null, benutzername?: string | null, bestaetigt?: number | null, bestellt?: number | null, bid: number, datum: any, ebm_1300?: number | null, einbaubegin?: any | null, eingetragen?: any | null, em_1600?: number | null, em_1900?: number | null, em_Fremd?: number | null, em_Keine?: number | null, em_Sb_110?: number | null, em_Sb_130?: number | null, em_Sb_300?: number | null, ersteller?: string | null, etappe?: string | null, freigegeben?: number | null, gespeichertam?: any | null, mischwerk?: string | null, report?: string | null, storniert?: number | null, vorarbeiter?: number | null } } };

export type SaveBestellungMutationVariables = Exact<{
  input: BestellungInput;
}>;


export type SaveBestellungMutation = { __typename?: 'Mutation', bestellung: { __typename?: 'BestellungMutations', save: { __typename?: 'Bestellung', bid: number, report?: string | null } } };

export type GetBelagsSortePdfQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetBelagsSortePdfQuery = { __typename?: 'Query', belagPdf?: Array<any> | null };

export type SaveBelagMutationVariables = Exact<{
  addbelag: Array<BelagInput> | BelagInput;
  updatebelag: Array<BelagInput> | BelagInput;
  delBelag: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type SaveBelagMutation = { __typename?: 'Mutation', belag: { __typename?: 'BelagMutations', save: { __typename?: 'MutationSaveOutputOfBelag', removed?: Array<number> | null, added?: Array<{ __typename?: 'Belag', id?: number | null, bSrcp?: string | null, bSbinder?: string | null, bSconcatName?: string | null, erstPruefFile?: string | null, inaktiv: boolean, bSzusatz?: string | null, firma: number, sorte: string, bindemittel: string, bm: number }> | null, updated?: Array<{ __typename?: 'Belag', id?: number | null, bSrcp?: string | null, bSbinder?: string | null, bSconcatName?: string | null, erstPruefFile?: string | null, inaktiv: boolean, bSzusatz?: string | null, firma: number, sorte: string, bindemittel: string, bm: number }> | null } } };

export type UploadBelagPdfMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  filename: Scalars['String']['input'];
  file: Scalars['String']['input'];
}>;


export type UploadBelagPdfMutation = { __typename?: 'Mutation', belag: { __typename?: 'BelagMutations', uploadPruefFile: string } };

export type GetNewsAndWartungForStartQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNewsAndWartungForStartQuery = { __typename?: 'Query', news: Array<{ __typename?: 'News', titel: string, ersteller: string, text: string, datum: any, id: number }>, wartung: Array<{ __typename?: 'Wartung', id: number, von: any, vontime?: any | null, bis: any, bistime?: any | null, typ: string, ersteller: string, eingetragen?: string | null }> };

export type AddDocumentMutationVariables = Exact<{
  input: Scalars['String']['input'];
  title: Scalars['String']['input'];
  desc: Scalars['String']['input'];
  filename: Scalars['String']['input'];
}>;


export type AddDocumentMutation = { __typename?: 'Mutation', dokumente: { __typename?: 'DokumentMutations', addDocument?: { __typename?: 'Dokumente', description_text: string, file_name: string, id?: number | null, title: string } | null } };

export type GetDokumentPdfQueryVariables = Exact<{
  path: Scalars['String']['input'];
}>;


export type GetDokumentPdfQuery = { __typename?: 'Query', dokumentPdf?: Array<any> | null };

export type GetDokumenteQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDokumenteQuery = { __typename?: 'Query', dokumente: Array<{ __typename?: 'Dokumente', id?: number | null, title: string, file_name: string, description_text: string }> };

export type RemoveDocumentsMutationVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type RemoveDocumentsMutation = { __typename?: 'Mutation', dokumente: { __typename?: 'DokumentMutations', removeDocuments: boolean } };

export type GetWartungsintervalleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWartungsintervalleQuery = { __typename?: 'Query', wartung: Array<{ __typename?: 'Wartung', id: number, bis: any, bistime?: any | null, eingetragen?: string | null, ersteller: string, typ: string, von: any, vontime?: any | null }> };

export type SaveWartungMutationVariables = Exact<{
  rows: Array<WartungInput> | WartungInput;
}>;


export type SaveWartungMutation = { __typename?: 'Mutation', wartung: { __typename?: 'WartungMutations', save: Array<{ __typename?: 'Wartung', bis: any, bistime?: any | null, eingetragen?: string | null, ersteller: string, id: number, typ: string, von: any, vontime?: any | null }> } };


export const GetUserDocument = gql`
    query GetUser {
  meInfos {
    firma
    id
    role
    upn
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export function useGetUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetBaustellenDocument = gql`
    query GetBaustellen {
  baustellen {
    baufuehrer
    baufuehrer_Nr
    baustelle
    baustelle_Name
    baustellennr
    eingetragen
    ersteller
    firma
    firma_nr
    id
    inaktiv
    ort
    vorarbeiter
    vorarbeiter_Nr
  }
  mitarbeiter(inaktiv: false) {
    id
    name
    vorname
    funktion
  }
}
    `;

/**
 * __useGetBaustellenQuery__
 *
 * To run a query within a React component, call `useGetBaustellenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaustellenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaustellenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaustellenQuery(baseOptions?: Apollo.QueryHookOptions<GetBaustellenQuery, GetBaustellenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaustellenQuery, GetBaustellenQueryVariables>(GetBaustellenDocument, options);
      }
export function useGetBaustellenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaustellenQuery, GetBaustellenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaustellenQuery, GetBaustellenQueryVariables>(GetBaustellenDocument, options);
        }
export function useGetBaustellenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBaustellenQuery, GetBaustellenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBaustellenQuery, GetBaustellenQueryVariables>(GetBaustellenDocument, options);
        }
export type GetBaustellenQueryHookResult = ReturnType<typeof useGetBaustellenQuery>;
export type GetBaustellenLazyQueryHookResult = ReturnType<typeof useGetBaustellenLazyQuery>;
export type GetBaustellenSuspenseQueryHookResult = ReturnType<typeof useGetBaustellenSuspenseQuery>;
export type GetBaustellenQueryResult = Apollo.QueryResult<GetBaustellenQuery, GetBaustellenQueryVariables>;
export const GetMitarbeiterDocument = gql`
    query GetMitarbeiter {
  mitarbeiter {
    firma
    eingetragen
    ersteller
    funktion
    id
    inaktiv
    mobile
    name
    personalnr
    vorname
  }
}
    `;

/**
 * __useGetMitarbeiterQuery__
 *
 * To run a query within a React component, call `useGetMitarbeiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMitarbeiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMitarbeiterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMitarbeiterQuery(baseOptions?: Apollo.QueryHookOptions<GetMitarbeiterQuery, GetMitarbeiterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMitarbeiterQuery, GetMitarbeiterQueryVariables>(GetMitarbeiterDocument, options);
      }
export function useGetMitarbeiterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMitarbeiterQuery, GetMitarbeiterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMitarbeiterQuery, GetMitarbeiterQueryVariables>(GetMitarbeiterDocument, options);
        }
export function useGetMitarbeiterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMitarbeiterQuery, GetMitarbeiterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMitarbeiterQuery, GetMitarbeiterQueryVariables>(GetMitarbeiterDocument, options);
        }
export type GetMitarbeiterQueryHookResult = ReturnType<typeof useGetMitarbeiterQuery>;
export type GetMitarbeiterLazyQueryHookResult = ReturnType<typeof useGetMitarbeiterLazyQuery>;
export type GetMitarbeiterSuspenseQueryHookResult = ReturnType<typeof useGetMitarbeiterSuspenseQuery>;
export type GetMitarbeiterQueryResult = Apollo.QueryResult<GetMitarbeiterQuery, GetMitarbeiterQueryVariables>;
export const GetNewsDocument = gql`
    query GetNews {
  news {
    id
    titel
    ersteller
    text
    datum
  }
}
    `;

/**
 * __useGetNewsQuery__
 *
 * To run a query within a React component, call `useGetNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNewsQuery(baseOptions?: Apollo.QueryHookOptions<GetNewsQuery, GetNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsQuery, GetNewsQueryVariables>(GetNewsDocument, options);
      }
export function useGetNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsQuery, GetNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsQuery, GetNewsQueryVariables>(GetNewsDocument, options);
        }
export function useGetNewsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNewsQuery, GetNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNewsQuery, GetNewsQueryVariables>(GetNewsDocument, options);
        }
export type GetNewsQueryHookResult = ReturnType<typeof useGetNewsQuery>;
export type GetNewsLazyQueryHookResult = ReturnType<typeof useGetNewsLazyQuery>;
export type GetNewsSuspenseQueryHookResult = ReturnType<typeof useGetNewsSuspenseQuery>;
export type GetNewsQueryResult = Apollo.QueryResult<GetNewsQuery, GetNewsQueryVariables>;
export const SaveBaustellenDocument = gql`
    mutation SaveBaustellen($addable: [BaustellenInput!]!, $updatable: [BaustellenInput!]!, $removable: [Int!]!) {
  baustellen {
    save(
      addBaustellen: $addable
      delBaustellen: $removable
      updateBaustellen: $updatable
    ) {
      added {
        baufuehrer
        baufuehrer_Nr
        baustelle
        baustelle_Name
        baustellennr
        eingetragen
        ersteller
        firma
        firma_nr
        id
        inaktiv
        ort
        vorarbeiter
        vorarbeiter_Nr
      }
      removed
      updated {
        baufuehrer
        baufuehrer_Nr
        baustelle
        baustelle_Name
        baustellennr
        eingetragen
        ersteller
        firma
        firma_nr
        id
        inaktiv
        ort
        vorarbeiter
        vorarbeiter_Nr
      }
    }
  }
}
    `;
export type SaveBaustellenMutationFn = Apollo.MutationFunction<SaveBaustellenMutation, SaveBaustellenMutationVariables>;

/**
 * __useSaveBaustellenMutation__
 *
 * To run a mutation, you first call `useSaveBaustellenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBaustellenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBaustellenMutation, { data, loading, error }] = useSaveBaustellenMutation({
 *   variables: {
 *      addable: // value for 'addable'
 *      updatable: // value for 'updatable'
 *      removable: // value for 'removable'
 *   },
 * });
 */
export function useSaveBaustellenMutation(baseOptions?: Apollo.MutationHookOptions<SaveBaustellenMutation, SaveBaustellenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveBaustellenMutation, SaveBaustellenMutationVariables>(SaveBaustellenDocument, options);
      }
export type SaveBaustellenMutationHookResult = ReturnType<typeof useSaveBaustellenMutation>;
export type SaveBaustellenMutationResult = Apollo.MutationResult<SaveBaustellenMutation>;
export type SaveBaustellenMutationOptions = Apollo.BaseMutationOptions<SaveBaustellenMutation, SaveBaustellenMutationVariables>;
export const SaveMitarbeiterDocument = gql`
    mutation SaveMitarbeiter($addmitarbeiter: [MitarbeiterInput!]!, $updatemitarbeiter: [MitarbeiterInput!]!, $delMitarbeiter: [Int!]!) {
  mitarbeiter {
    save(
      addmitarbeiter: $addmitarbeiter
      updatemitarbeiter: $updatemitarbeiter
      delMitarbeiter: $delMitarbeiter
    ) {
      added {
        eingetragen
        ersteller
        firma
        funktion
        id
        inaktiv
        mobile
        name
        personalnr
        vorname
      }
      updated {
        eingetragen
        ersteller
        firma
        funktion
        id
        inaktiv
        mobile
        name
        personalnr
        vorname
      }
      removed
    }
  }
}
    `;
export type SaveMitarbeiterMutationFn = Apollo.MutationFunction<SaveMitarbeiterMutation, SaveMitarbeiterMutationVariables>;

/**
 * __useSaveMitarbeiterMutation__
 *
 * To run a mutation, you first call `useSaveMitarbeiterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMitarbeiterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMitarbeiterMutation, { data, loading, error }] = useSaveMitarbeiterMutation({
 *   variables: {
 *      addmitarbeiter: // value for 'addmitarbeiter'
 *      updatemitarbeiter: // value for 'updatemitarbeiter'
 *      delMitarbeiter: // value for 'delMitarbeiter'
 *   },
 * });
 */
export function useSaveMitarbeiterMutation(baseOptions?: Apollo.MutationHookOptions<SaveMitarbeiterMutation, SaveMitarbeiterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveMitarbeiterMutation, SaveMitarbeiterMutationVariables>(SaveMitarbeiterDocument, options);
      }
export type SaveMitarbeiterMutationHookResult = ReturnType<typeof useSaveMitarbeiterMutation>;
export type SaveMitarbeiterMutationResult = Apollo.MutationResult<SaveMitarbeiterMutation>;
export type SaveMitarbeiterMutationOptions = Apollo.BaseMutationOptions<SaveMitarbeiterMutation, SaveMitarbeiterMutationVariables>;
export const AddNewsDocument = gql`
    mutation addNews($news: NewsInput!) {
  news {
    addNews(news: $news) {
      datum
      ersteller
      id
      text
      titel
    }
  }
}
    `;
export type AddNewsMutationFn = Apollo.MutationFunction<AddNewsMutation, AddNewsMutationVariables>;

/**
 * __useAddNewsMutation__
 *
 * To run a mutation, you first call `useAddNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewsMutation, { data, loading, error }] = useAddNewsMutation({
 *   variables: {
 *      news: // value for 'news'
 *   },
 * });
 */
export function useAddNewsMutation(baseOptions?: Apollo.MutationHookOptions<AddNewsMutation, AddNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewsMutation, AddNewsMutationVariables>(AddNewsDocument, options);
      }
export type AddNewsMutationHookResult = ReturnType<typeof useAddNewsMutation>;
export type AddNewsMutationResult = Apollo.MutationResult<AddNewsMutation>;
export type AddNewsMutationOptions = Apollo.BaseMutationOptions<AddNewsMutation, AddNewsMutationVariables>;
export const RemoveNewsDocument = gql`
    mutation removeNews($id: Int!) {
  news {
    del(id: $id)
  }
}
    `;
export type RemoveNewsMutationFn = Apollo.MutationFunction<RemoveNewsMutation, RemoveNewsMutationVariables>;

/**
 * __useRemoveNewsMutation__
 *
 * To run a mutation, you first call `useRemoveNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNewsMutation, { data, loading, error }] = useRemoveNewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveNewsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveNewsMutation, RemoveNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveNewsMutation, RemoveNewsMutationVariables>(RemoveNewsDocument, options);
      }
export type RemoveNewsMutationHookResult = ReturnType<typeof useRemoveNewsMutation>;
export type RemoveNewsMutationResult = Apollo.MutationResult<RemoveNewsMutation>;
export type RemoveNewsMutationOptions = Apollo.BaseMutationOptions<RemoveNewsMutation, RemoveNewsMutationVariables>;
export const UpdateNewsDocument = gql`
    mutation updateNews($news: NewsInput!) {
  news {
    update(news: $news) {
      id
      titel
      text
      datum
    }
  }
}
    `;
export type UpdateNewsMutationFn = Apollo.MutationFunction<UpdateNewsMutation, UpdateNewsMutationVariables>;

/**
 * __useUpdateNewsMutation__
 *
 * To run a mutation, you first call `useUpdateNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewsMutation, { data, loading, error }] = useUpdateNewsMutation({
 *   variables: {
 *      news: // value for 'news'
 *   },
 * });
 */
export function useUpdateNewsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNewsMutation, UpdateNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNewsMutation, UpdateNewsMutationVariables>(UpdateNewsDocument, options);
      }
export type UpdateNewsMutationHookResult = ReturnType<typeof useUpdateNewsMutation>;
export type UpdateNewsMutationResult = Apollo.MutationResult<UpdateNewsMutation>;
export type UpdateNewsMutationOptions = Apollo.BaseMutationOptions<UpdateNewsMutation, UpdateNewsMutationVariables>;
export const DeleteBestellungDocument = gql`
    mutation DeleteBestellung($id: Int!) {
  bestellung {
    del(id: $id)
  }
}
    `;
export type DeleteBestellungMutationFn = Apollo.MutationFunction<DeleteBestellungMutation, DeleteBestellungMutationVariables>;

/**
 * __useDeleteBestellungMutation__
 *
 * To run a mutation, you first call `useDeleteBestellungMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBestellungMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBestellungMutation, { data, loading, error }] = useDeleteBestellungMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBestellungMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBestellungMutation, DeleteBestellungMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBestellungMutation, DeleteBestellungMutationVariables>(DeleteBestellungDocument, options);
      }
export type DeleteBestellungMutationHookResult = ReturnType<typeof useDeleteBestellungMutation>;
export type DeleteBestellungMutationResult = Apollo.MutationResult<DeleteBestellungMutation>;
export type DeleteBestellungMutationOptions = Apollo.BaseMutationOptions<DeleteBestellungMutation, DeleteBestellungMutationVariables>;
export const GetBelagDocument = gql`
    query GetBelag($inaktiv: Boolean) {
  belag(inaktiv: $inaktiv) {
    bindemittel
    bm
    bSbinder
    bSconcatName
    bSrcp
    bSsorte
    bStyp
    bSzusatz
    eingetragen
    ersteller
    ersteller_nr
    erstPruefFile
    firma
    id
    inaktiv
    sorte
  }
}
    `;

/**
 * __useGetBelagQuery__
 *
 * To run a query within a React component, call `useGetBelagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBelagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBelagQuery({
 *   variables: {
 *      inaktiv: // value for 'inaktiv'
 *   },
 * });
 */
export function useGetBelagQuery(baseOptions?: Apollo.QueryHookOptions<GetBelagQuery, GetBelagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBelagQuery, GetBelagQueryVariables>(GetBelagDocument, options);
      }
export function useGetBelagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBelagQuery, GetBelagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBelagQuery, GetBelagQueryVariables>(GetBelagDocument, options);
        }
export function useGetBelagSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBelagQuery, GetBelagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBelagQuery, GetBelagQueryVariables>(GetBelagDocument, options);
        }
export type GetBelagQueryHookResult = ReturnType<typeof useGetBelagQuery>;
export type GetBelagLazyQueryHookResult = ReturnType<typeof useGetBelagLazyQuery>;
export type GetBelagSuspenseQueryHookResult = ReturnType<typeof useGetBelagSuspenseQuery>;
export type GetBelagQueryResult = Apollo.QueryResult<GetBelagQuery, GetBelagQueryVariables>;
export const GetBelagOptionsDocument = gql`
    query GetBelagOptions {
  Recpercent: typingOptions(id: 6) {
    option_value
  }
  Binder: typingOptions(id: 5) {
    option_value
  }
  Belagssorte: typingOptions(id: 2) {
    option_value
  }
  BSTyp: typingOptions(id: 4) {
    option_value
  }
  BSZusatz: typingOptions(id: 13) {
    option_value
  }
  Wann: typingOptions(id: 11) {
    option_value
    option_nr
  }
}
    `;

/**
 * __useGetBelagOptionsQuery__
 *
 * To run a query within a React component, call `useGetBelagOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBelagOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBelagOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBelagOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetBelagOptionsQuery, GetBelagOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBelagOptionsQuery, GetBelagOptionsQueryVariables>(GetBelagOptionsDocument, options);
      }
export function useGetBelagOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBelagOptionsQuery, GetBelagOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBelagOptionsQuery, GetBelagOptionsQueryVariables>(GetBelagOptionsDocument, options);
        }
export function useGetBelagOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBelagOptionsQuery, GetBelagOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBelagOptionsQuery, GetBelagOptionsQueryVariables>(GetBelagOptionsDocument, options);
        }
export type GetBelagOptionsQueryHookResult = ReturnType<typeof useGetBelagOptionsQuery>;
export type GetBelagOptionsLazyQueryHookResult = ReturnType<typeof useGetBelagOptionsLazyQuery>;
export type GetBelagOptionsSuspenseQueryHookResult = ReturnType<typeof useGetBelagOptionsSuspenseQuery>;
export type GetBelagOptionsQueryResult = Apollo.QueryResult<GetBelagOptionsQuery, GetBelagOptionsQueryVariables>;
export const GetBelagsbestellungPdfDocument = gql`
    query GetBelagsbestellungPdf($path: String!) {
  belagsbestellungPdf(path: $path)
}
    `;

/**
 * __useGetBelagsbestellungPdfQuery__
 *
 * To run a query within a React component, call `useGetBelagsbestellungPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBelagsbestellungPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBelagsbestellungPdfQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useGetBelagsbestellungPdfQuery(baseOptions: Apollo.QueryHookOptions<GetBelagsbestellungPdfQuery, GetBelagsbestellungPdfQueryVariables> & ({ variables: GetBelagsbestellungPdfQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBelagsbestellungPdfQuery, GetBelagsbestellungPdfQueryVariables>(GetBelagsbestellungPdfDocument, options);
      }
export function useGetBelagsbestellungPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBelagsbestellungPdfQuery, GetBelagsbestellungPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBelagsbestellungPdfQuery, GetBelagsbestellungPdfQueryVariables>(GetBelagsbestellungPdfDocument, options);
        }
export function useGetBelagsbestellungPdfSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBelagsbestellungPdfQuery, GetBelagsbestellungPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBelagsbestellungPdfQuery, GetBelagsbestellungPdfQueryVariables>(GetBelagsbestellungPdfDocument, options);
        }
export type GetBelagsbestellungPdfQueryHookResult = ReturnType<typeof useGetBelagsbestellungPdfQuery>;
export type GetBelagsbestellungPdfLazyQueryHookResult = ReturnType<typeof useGetBelagsbestellungPdfLazyQuery>;
export type GetBelagsbestellungPdfSuspenseQueryHookResult = ReturnType<typeof useGetBelagsbestellungPdfSuspenseQuery>;
export type GetBelagsbestellungPdfQueryResult = Apollo.QueryResult<GetBelagsbestellungPdfQuery, GetBelagsbestellungPdfQueryVariables>;
export const GetBestellArchivDocument = gql`
    query GetBestellArchiv($freigabe: Boolean, $monat: Int, $jahr: Int, $nurmeine: Boolean) {
  bestellungDataArchiv(
    freigabe: $freigabe
    monat: $monat
    jahr: $jahr
    nurmeine: $nurmeine
  ) {
    bid
    datum
    eingetragen
    mischwerk
    baustelle_Name
    baustelle
    einbaubegin
    bestellt
    freigegeben
    storniert
    freigegeben
    bestaetigt
    report
    baufuehrer_name
    ersteller
  }
}
    `;

/**
 * __useGetBestellArchivQuery__
 *
 * To run a query within a React component, call `useGetBestellArchivQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBestellArchivQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBestellArchivQuery({
 *   variables: {
 *      freigabe: // value for 'freigabe'
 *      monat: // value for 'monat'
 *      jahr: // value for 'jahr'
 *      nurmeine: // value for 'nurmeine'
 *   },
 * });
 */
export function useGetBestellArchivQuery(baseOptions?: Apollo.QueryHookOptions<GetBestellArchivQuery, GetBestellArchivQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBestellArchivQuery, GetBestellArchivQueryVariables>(GetBestellArchivDocument, options);
      }
export function useGetBestellArchivLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBestellArchivQuery, GetBestellArchivQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBestellArchivQuery, GetBestellArchivQueryVariables>(GetBestellArchivDocument, options);
        }
export function useGetBestellArchivSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBestellArchivQuery, GetBestellArchivQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBestellArchivQuery, GetBestellArchivQueryVariables>(GetBestellArchivDocument, options);
        }
export type GetBestellArchivQueryHookResult = ReturnType<typeof useGetBestellArchivQuery>;
export type GetBestellArchivLazyQueryHookResult = ReturnType<typeof useGetBestellArchivLazyQuery>;
export type GetBestellArchivSuspenseQueryHookResult = ReturnType<typeof useGetBestellArchivSuspenseQuery>;
export type GetBestellArchivQueryResult = Apollo.QueryResult<GetBestellArchivQuery, GetBestellArchivQueryVariables>;
export const GetBestellungDocument = gql`
    query GetBestellung($id: Int!) {
  bestellungData(bid: $id) {
    stv
    baufuehrer
    baufuehrer_name
    baustelle
    baustelle_Name
    baustellenverantwortlicher
    baustellenverantwortlicher_nr
    bauteil
    belagbestellung {
      belag
      belagssorte
      bemerkung
      bestellDatum
      bestellid
      bindemittel
      bSconcatName
      bSindex
      erstPruefFile
      geplante_Menge
      id
      ladezeitMin
      lLmenge
      rc_Anteil
      typ
      wann
      zusatz
    }
    bemerkung
    benutzername
    bestaetigt
    bestellt
    bid
    datum
    ebm_1300
    einbaubegin
    eingetragen
    em_1600
    em_1900
    em_Fremd
    em_Keine
    em_Sb_110
    em_Sb_130
    em_Sb_300
    ersteller
    etappe
    freigegeben
    report
    gespeichertam
    ladeliste {
      belag
      belags_Sorte
      bestellid
      bSindex
      id
      ladezeit
      lkw
      lkw_Art
      lkw_art_nr
      lkw_Plus_Fuhre
      menge
      transport
    }
    mischwerk
    storniert
    vorarbeiter
  }
}
    `;

/**
 * __useGetBestellungQuery__
 *
 * To run a query within a React component, call `useGetBestellungQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBestellungQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBestellungQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBestellungQuery(baseOptions: Apollo.QueryHookOptions<GetBestellungQuery, GetBestellungQueryVariables> & ({ variables: GetBestellungQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBestellungQuery, GetBestellungQueryVariables>(GetBestellungDocument, options);
      }
export function useGetBestellungLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBestellungQuery, GetBestellungQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBestellungQuery, GetBestellungQueryVariables>(GetBestellungDocument, options);
        }
export function useGetBestellungSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBestellungQuery, GetBestellungQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBestellungQuery, GetBestellungQueryVariables>(GetBestellungDocument, options);
        }
export type GetBestellungQueryHookResult = ReturnType<typeof useGetBestellungQuery>;
export type GetBestellungLazyQueryHookResult = ReturnType<typeof useGetBestellungLazyQuery>;
export type GetBestellungSuspenseQueryHookResult = ReturnType<typeof useGetBestellungSuspenseQuery>;
export type GetBestellungQueryResult = Apollo.QueryResult<GetBestellungQuery, GetBestellungQueryVariables>;
export const GetBestellungOptionsDocument = gql`
    query GetBestellungOptions {
  baustellen(inaktiv: false) {
    baustellennr
    baustelle_Name
    id
  }
  vorarbeiter: mitarbeiter(funktion: 2, inaktiv: false) {
    vorname
    name
    id
    personalnr
  }
  baufuehrer: mitarbeiter(funktion: 1, inaktiv: false) {
    vorname
    name
    id
    personalnr
  }
  typingOptions(id: 14) {
    option_value
  }
}
    `;

/**
 * __useGetBestellungOptionsQuery__
 *
 * To run a query within a React component, call `useGetBestellungOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBestellungOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBestellungOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBestellungOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetBestellungOptionsQuery, GetBestellungOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBestellungOptionsQuery, GetBestellungOptionsQueryVariables>(GetBestellungOptionsDocument, options);
      }
export function useGetBestellungOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBestellungOptionsQuery, GetBestellungOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBestellungOptionsQuery, GetBestellungOptionsQueryVariables>(GetBestellungOptionsDocument, options);
        }
export function useGetBestellungOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBestellungOptionsQuery, GetBestellungOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBestellungOptionsQuery, GetBestellungOptionsQueryVariables>(GetBestellungOptionsDocument, options);
        }
export type GetBestellungOptionsQueryHookResult = ReturnType<typeof useGetBestellungOptionsQuery>;
export type GetBestellungOptionsLazyQueryHookResult = ReturnType<typeof useGetBestellungOptionsLazyQuery>;
export type GetBestellungOptionsSuspenseQueryHookResult = ReturnType<typeof useGetBestellungOptionsSuspenseQuery>;
export type GetBestellungOptionsQueryResult = Apollo.QueryResult<GetBestellungOptionsQuery, GetBestellungOptionsQueryVariables>;
export const GetBestellungenDocument = gql`
    query GetBestellungen {
  bestellungDataAll {
    bid
    datum
    eingetragen
    mischwerk
    baustelle_Name
    baustelle
    einbaubegin
    bestellt
    freigegeben
    storniert
    freigegeben
    bestaetigt
    report
    baufuehrer_name
  }
}
    `;

/**
 * __useGetBestellungenQuery__
 *
 * To run a query within a React component, call `useGetBestellungenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBestellungenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBestellungenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBestellungenQuery(baseOptions?: Apollo.QueryHookOptions<GetBestellungenQuery, GetBestellungenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBestellungenQuery, GetBestellungenQueryVariables>(GetBestellungenDocument, options);
      }
export function useGetBestellungenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBestellungenQuery, GetBestellungenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBestellungenQuery, GetBestellungenQueryVariables>(GetBestellungenDocument, options);
        }
export function useGetBestellungenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBestellungenQuery, GetBestellungenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBestellungenQuery, GetBestellungenQueryVariables>(GetBestellungenDocument, options);
        }
export type GetBestellungenQueryHookResult = ReturnType<typeof useGetBestellungenQuery>;
export type GetBestellungenLazyQueryHookResult = ReturnType<typeof useGetBestellungenLazyQuery>;
export type GetBestellungenSuspenseQueryHookResult = ReturnType<typeof useGetBestellungenSuspenseQuery>;
export type GetBestellungenQueryResult = Apollo.QueryResult<GetBestellungenQuery, GetBestellungenQueryVariables>;
export const GetLadelisteOptionsDocument = gql`
    query GetLadelisteOptions {
  LKW: typingOptions(id: 1) {
    option_nr
    option_value
  }
}
    `;

/**
 * __useGetLadelisteOptionsQuery__
 *
 * To run a query within a React component, call `useGetLadelisteOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLadelisteOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLadelisteOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLadelisteOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetLadelisteOptionsQuery, GetLadelisteOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLadelisteOptionsQuery, GetLadelisteOptionsQueryVariables>(GetLadelisteOptionsDocument, options);
      }
export function useGetLadelisteOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLadelisteOptionsQuery, GetLadelisteOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLadelisteOptionsQuery, GetLadelisteOptionsQueryVariables>(GetLadelisteOptionsDocument, options);
        }
export function useGetLadelisteOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLadelisteOptionsQuery, GetLadelisteOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLadelisteOptionsQuery, GetLadelisteOptionsQueryVariables>(GetLadelisteOptionsDocument, options);
        }
export type GetLadelisteOptionsQueryHookResult = ReturnType<typeof useGetLadelisteOptionsQuery>;
export type GetLadelisteOptionsLazyQueryHookResult = ReturnType<typeof useGetLadelisteOptionsLazyQuery>;
export type GetLadelisteOptionsSuspenseQueryHookResult = ReturnType<typeof useGetLadelisteOptionsSuspenseQuery>;
export type GetLadelisteOptionsQueryResult = Apollo.QueryResult<GetLadelisteOptionsQuery, GetLadelisteOptionsQueryVariables>;
export const GetReportDocument = gql`
    query GetReport($filename: String!) {
  reportPdf(path: $filename)
}
    `;

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useGetReportQuery(baseOptions: Apollo.QueryHookOptions<GetReportQuery, GetReportQueryVariables> & ({ variables: GetReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
      }
export function useGetReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
        }
export function useGetReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
        }
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<typeof useGetReportLazyQuery>;
export type GetReportSuspenseQueryHookResult = ReturnType<typeof useGetReportSuspenseQuery>;
export type GetReportQueryResult = Apollo.QueryResult<GetReportQuery, GetReportQueryVariables>;
export const KopieBestellungDocument = gql`
    mutation KopieBestellung($id: Int!) {
  bestellung {
    kopie(id: $id) {
      baufuehrer
      baufuehrer_name
      baustelle
      baustelle_Name
      baustellenverantwortlicher
      baustellenverantwortlicher_nr
      bauteil
      bemerkung
      benutzername
      bestaetigt
      bestellt
      bid
      datum
      ebm_1300
      einbaubegin
      eingetragen
      em_1600
      em_1900
      em_Fremd
      em_Keine
      em_Sb_110
      em_Sb_130
      em_Sb_300
      ersteller
      etappe
      freigegeben
      gespeichertam
      mischwerk
      report
      storniert
      vorarbeiter
    }
  }
}
    `;
export type KopieBestellungMutationFn = Apollo.MutationFunction<KopieBestellungMutation, KopieBestellungMutationVariables>;

/**
 * __useKopieBestellungMutation__
 *
 * To run a mutation, you first call `useKopieBestellungMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKopieBestellungMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kopieBestellungMutation, { data, loading, error }] = useKopieBestellungMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKopieBestellungMutation(baseOptions?: Apollo.MutationHookOptions<KopieBestellungMutation, KopieBestellungMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KopieBestellungMutation, KopieBestellungMutationVariables>(KopieBestellungDocument, options);
      }
export type KopieBestellungMutationHookResult = ReturnType<typeof useKopieBestellungMutation>;
export type KopieBestellungMutationResult = Apollo.MutationResult<KopieBestellungMutation>;
export type KopieBestellungMutationOptions = Apollo.BaseMutationOptions<KopieBestellungMutation, KopieBestellungMutationVariables>;
export const SaveBestellungDocument = gql`
    mutation SaveBestellung($input: BestellungInput!) {
  bestellung {
    save(bestellung: $input) {
      bid
      report
    }
  }
}
    `;
export type SaveBestellungMutationFn = Apollo.MutationFunction<SaveBestellungMutation, SaveBestellungMutationVariables>;

/**
 * __useSaveBestellungMutation__
 *
 * To run a mutation, you first call `useSaveBestellungMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBestellungMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBestellungMutation, { data, loading, error }] = useSaveBestellungMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBestellungMutation(baseOptions?: Apollo.MutationHookOptions<SaveBestellungMutation, SaveBestellungMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveBestellungMutation, SaveBestellungMutationVariables>(SaveBestellungDocument, options);
      }
export type SaveBestellungMutationHookResult = ReturnType<typeof useSaveBestellungMutation>;
export type SaveBestellungMutationResult = Apollo.MutationResult<SaveBestellungMutation>;
export type SaveBestellungMutationOptions = Apollo.BaseMutationOptions<SaveBestellungMutation, SaveBestellungMutationVariables>;
export const GetBelagsSortePdfDocument = gql`
    query GetBelagsSortePDF($id: Int!) {
  belagPdf(id: $id)
}
    `;

/**
 * __useGetBelagsSortePdfQuery__
 *
 * To run a query within a React component, call `useGetBelagsSortePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBelagsSortePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBelagsSortePdfQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBelagsSortePdfQuery(baseOptions: Apollo.QueryHookOptions<GetBelagsSortePdfQuery, GetBelagsSortePdfQueryVariables> & ({ variables: GetBelagsSortePdfQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBelagsSortePdfQuery, GetBelagsSortePdfQueryVariables>(GetBelagsSortePdfDocument, options);
      }
export function useGetBelagsSortePdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBelagsSortePdfQuery, GetBelagsSortePdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBelagsSortePdfQuery, GetBelagsSortePdfQueryVariables>(GetBelagsSortePdfDocument, options);
        }
export function useGetBelagsSortePdfSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBelagsSortePdfQuery, GetBelagsSortePdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBelagsSortePdfQuery, GetBelagsSortePdfQueryVariables>(GetBelagsSortePdfDocument, options);
        }
export type GetBelagsSortePdfQueryHookResult = ReturnType<typeof useGetBelagsSortePdfQuery>;
export type GetBelagsSortePdfLazyQueryHookResult = ReturnType<typeof useGetBelagsSortePdfLazyQuery>;
export type GetBelagsSortePdfSuspenseQueryHookResult = ReturnType<typeof useGetBelagsSortePdfSuspenseQuery>;
export type GetBelagsSortePdfQueryResult = Apollo.QueryResult<GetBelagsSortePdfQuery, GetBelagsSortePdfQueryVariables>;
export const SaveBelagDocument = gql`
    mutation SaveBelag($addbelag: [BelagInput!]!, $updatebelag: [BelagInput!]!, $delBelag: [Int!]!) {
  belag {
    save(addbelag: $addbelag, updatebelag: $updatebelag, delBelag: $delBelag) {
      added {
        id
        bSrcp
        bSbinder
        bSconcatName
        erstPruefFile
        inaktiv
        bSzusatz
        firma
        sorte
        bindemittel
        bm
      }
      removed
      updated {
        id
        bSrcp
        bSbinder
        bSconcatName
        erstPruefFile
        inaktiv
        bSzusatz
        firma
        sorte
        bindemittel
        bm
      }
    }
  }
}
    `;
export type SaveBelagMutationFn = Apollo.MutationFunction<SaveBelagMutation, SaveBelagMutationVariables>;

/**
 * __useSaveBelagMutation__
 *
 * To run a mutation, you first call `useSaveBelagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBelagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBelagMutation, { data, loading, error }] = useSaveBelagMutation({
 *   variables: {
 *      addbelag: // value for 'addbelag'
 *      updatebelag: // value for 'updatebelag'
 *      delBelag: // value for 'delBelag'
 *   },
 * });
 */
export function useSaveBelagMutation(baseOptions?: Apollo.MutationHookOptions<SaveBelagMutation, SaveBelagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveBelagMutation, SaveBelagMutationVariables>(SaveBelagDocument, options);
      }
export type SaveBelagMutationHookResult = ReturnType<typeof useSaveBelagMutation>;
export type SaveBelagMutationResult = Apollo.MutationResult<SaveBelagMutation>;
export type SaveBelagMutationOptions = Apollo.BaseMutationOptions<SaveBelagMutation, SaveBelagMutationVariables>;
export const UploadBelagPdfDocument = gql`
    mutation UploadBelagPDF($id: Int!, $filename: String!, $file: String!) {
  belag {
    uploadPruefFile(belagid: $id, filename: $filename, input: $file)
  }
}
    `;
export type UploadBelagPdfMutationFn = Apollo.MutationFunction<UploadBelagPdfMutation, UploadBelagPdfMutationVariables>;

/**
 * __useUploadBelagPdfMutation__
 *
 * To run a mutation, you first call `useUploadBelagPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBelagPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBelagPdfMutation, { data, loading, error }] = useUploadBelagPdfMutation({
 *   variables: {
 *      id: // value for 'id'
 *      filename: // value for 'filename'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadBelagPdfMutation(baseOptions?: Apollo.MutationHookOptions<UploadBelagPdfMutation, UploadBelagPdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBelagPdfMutation, UploadBelagPdfMutationVariables>(UploadBelagPdfDocument, options);
      }
export type UploadBelagPdfMutationHookResult = ReturnType<typeof useUploadBelagPdfMutation>;
export type UploadBelagPdfMutationResult = Apollo.MutationResult<UploadBelagPdfMutation>;
export type UploadBelagPdfMutationOptions = Apollo.BaseMutationOptions<UploadBelagPdfMutation, UploadBelagPdfMutationVariables>;
export const GetNewsAndWartungForStartDocument = gql`
    query GetNewsAndWartungForStart {
  news {
    titel
    ersteller
    text
    datum
    id
  }
  wartung {
    id
    von
    vontime
    bis
    bistime
    typ
    ersteller
    eingetragen
  }
}
    `;

/**
 * __useGetNewsAndWartungForStartQuery__
 *
 * To run a query within a React component, call `useGetNewsAndWartungForStartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsAndWartungForStartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsAndWartungForStartQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNewsAndWartungForStartQuery(baseOptions?: Apollo.QueryHookOptions<GetNewsAndWartungForStartQuery, GetNewsAndWartungForStartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsAndWartungForStartQuery, GetNewsAndWartungForStartQueryVariables>(GetNewsAndWartungForStartDocument, options);
      }
export function useGetNewsAndWartungForStartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsAndWartungForStartQuery, GetNewsAndWartungForStartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsAndWartungForStartQuery, GetNewsAndWartungForStartQueryVariables>(GetNewsAndWartungForStartDocument, options);
        }
export function useGetNewsAndWartungForStartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNewsAndWartungForStartQuery, GetNewsAndWartungForStartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNewsAndWartungForStartQuery, GetNewsAndWartungForStartQueryVariables>(GetNewsAndWartungForStartDocument, options);
        }
export type GetNewsAndWartungForStartQueryHookResult = ReturnType<typeof useGetNewsAndWartungForStartQuery>;
export type GetNewsAndWartungForStartLazyQueryHookResult = ReturnType<typeof useGetNewsAndWartungForStartLazyQuery>;
export type GetNewsAndWartungForStartSuspenseQueryHookResult = ReturnType<typeof useGetNewsAndWartungForStartSuspenseQuery>;
export type GetNewsAndWartungForStartQueryResult = Apollo.QueryResult<GetNewsAndWartungForStartQuery, GetNewsAndWartungForStartQueryVariables>;
export const AddDocumentDocument = gql`
    mutation AddDocument($input: String!, $title: String!, $desc: String!, $filename: String!) {
  dokumente {
    addDocument(input: $input, title: $title, desc: $desc, filename: $filename) {
      description_text
      file_name
      id
      title
    }
  }
}
    `;
export type AddDocumentMutationFn = Apollo.MutationFunction<AddDocumentMutation, AddDocumentMutationVariables>;

/**
 * __useAddDocumentMutation__
 *
 * To run a mutation, you first call `useAddDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentMutation, { data, loading, error }] = useAddDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      title: // value for 'title'
 *      desc: // value for 'desc'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useAddDocumentMutation(baseOptions?: Apollo.MutationHookOptions<AddDocumentMutation, AddDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDocumentMutation, AddDocumentMutationVariables>(AddDocumentDocument, options);
      }
export type AddDocumentMutationHookResult = ReturnType<typeof useAddDocumentMutation>;
export type AddDocumentMutationResult = Apollo.MutationResult<AddDocumentMutation>;
export type AddDocumentMutationOptions = Apollo.BaseMutationOptions<AddDocumentMutation, AddDocumentMutationVariables>;
export const GetDokumentPdfDocument = gql`
    query GetDokumentPDF($path: String!) {
  dokumentPdf(path: $path)
}
    `;

/**
 * __useGetDokumentPdfQuery__
 *
 * To run a query within a React component, call `useGetDokumentPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDokumentPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDokumentPdfQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useGetDokumentPdfQuery(baseOptions: Apollo.QueryHookOptions<GetDokumentPdfQuery, GetDokumentPdfQueryVariables> & ({ variables: GetDokumentPdfQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDokumentPdfQuery, GetDokumentPdfQueryVariables>(GetDokumentPdfDocument, options);
      }
export function useGetDokumentPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDokumentPdfQuery, GetDokumentPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDokumentPdfQuery, GetDokumentPdfQueryVariables>(GetDokumentPdfDocument, options);
        }
export function useGetDokumentPdfSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDokumentPdfQuery, GetDokumentPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDokumentPdfQuery, GetDokumentPdfQueryVariables>(GetDokumentPdfDocument, options);
        }
export type GetDokumentPdfQueryHookResult = ReturnType<typeof useGetDokumentPdfQuery>;
export type GetDokumentPdfLazyQueryHookResult = ReturnType<typeof useGetDokumentPdfLazyQuery>;
export type GetDokumentPdfSuspenseQueryHookResult = ReturnType<typeof useGetDokumentPdfSuspenseQuery>;
export type GetDokumentPdfQueryResult = Apollo.QueryResult<GetDokumentPdfQuery, GetDokumentPdfQueryVariables>;
export const GetDokumenteDocument = gql`
    query GetDokumente {
  dokumente {
    id
    title
    file_name
    description_text
  }
}
    `;

/**
 * __useGetDokumenteQuery__
 *
 * To run a query within a React component, call `useGetDokumenteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDokumenteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDokumenteQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDokumenteQuery(baseOptions?: Apollo.QueryHookOptions<GetDokumenteQuery, GetDokumenteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDokumenteQuery, GetDokumenteQueryVariables>(GetDokumenteDocument, options);
      }
export function useGetDokumenteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDokumenteQuery, GetDokumenteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDokumenteQuery, GetDokumenteQueryVariables>(GetDokumenteDocument, options);
        }
export function useGetDokumenteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDokumenteQuery, GetDokumenteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDokumenteQuery, GetDokumenteQueryVariables>(GetDokumenteDocument, options);
        }
export type GetDokumenteQueryHookResult = ReturnType<typeof useGetDokumenteQuery>;
export type GetDokumenteLazyQueryHookResult = ReturnType<typeof useGetDokumenteLazyQuery>;
export type GetDokumenteSuspenseQueryHookResult = ReturnType<typeof useGetDokumenteSuspenseQuery>;
export type GetDokumenteQueryResult = Apollo.QueryResult<GetDokumenteQuery, GetDokumenteQueryVariables>;
export const RemoveDocumentsDocument = gql`
    mutation RemoveDocuments($ids: [Int!]!) {
  dokumente {
    removeDocuments(ids: $ids)
  }
}
    `;
export type RemoveDocumentsMutationFn = Apollo.MutationFunction<RemoveDocumentsMutation, RemoveDocumentsMutationVariables>;

/**
 * __useRemoveDocumentsMutation__
 *
 * To run a mutation, you first call `useRemoveDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDocumentsMutation, { data, loading, error }] = useRemoveDocumentsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDocumentsMutation, RemoveDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDocumentsMutation, RemoveDocumentsMutationVariables>(RemoveDocumentsDocument, options);
      }
export type RemoveDocumentsMutationHookResult = ReturnType<typeof useRemoveDocumentsMutation>;
export type RemoveDocumentsMutationResult = Apollo.MutationResult<RemoveDocumentsMutation>;
export type RemoveDocumentsMutationOptions = Apollo.BaseMutationOptions<RemoveDocumentsMutation, RemoveDocumentsMutationVariables>;
export const GetWartungsintervalleDocument = gql`
    query GetWartungsintervalle {
  wartung {
    id
    bis
    bistime
    eingetragen
    ersteller
    typ
    von
    vontime
  }
}
    `;

/**
 * __useGetWartungsintervalleQuery__
 *
 * To run a query within a React component, call `useGetWartungsintervalleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWartungsintervalleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWartungsintervalleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWartungsintervalleQuery(baseOptions?: Apollo.QueryHookOptions<GetWartungsintervalleQuery, GetWartungsintervalleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWartungsintervalleQuery, GetWartungsintervalleQueryVariables>(GetWartungsintervalleDocument, options);
      }
export function useGetWartungsintervalleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWartungsintervalleQuery, GetWartungsintervalleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWartungsintervalleQuery, GetWartungsintervalleQueryVariables>(GetWartungsintervalleDocument, options);
        }
export function useGetWartungsintervalleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWartungsintervalleQuery, GetWartungsintervalleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWartungsintervalleQuery, GetWartungsintervalleQueryVariables>(GetWartungsintervalleDocument, options);
        }
export type GetWartungsintervalleQueryHookResult = ReturnType<typeof useGetWartungsintervalleQuery>;
export type GetWartungsintervalleLazyQueryHookResult = ReturnType<typeof useGetWartungsintervalleLazyQuery>;
export type GetWartungsintervalleSuspenseQueryHookResult = ReturnType<typeof useGetWartungsintervalleSuspenseQuery>;
export type GetWartungsintervalleQueryResult = Apollo.QueryResult<GetWartungsintervalleQuery, GetWartungsintervalleQueryVariables>;
export const SaveWartungDocument = gql`
    mutation SaveWartung($rows: [WartungInput!]!) {
  wartung {
    save(rows: $rows) {
      bis
      bistime
      eingetragen
      ersteller
      id
      typ
      von
      vontime
    }
  }
}
    `;
export type SaveWartungMutationFn = Apollo.MutationFunction<SaveWartungMutation, SaveWartungMutationVariables>;

/**
 * __useSaveWartungMutation__
 *
 * To run a mutation, you first call `useSaveWartungMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWartungMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWartungMutation, { data, loading, error }] = useSaveWartungMutation({
 *   variables: {
 *      rows: // value for 'rows'
 *   },
 * });
 */
export function useSaveWartungMutation(baseOptions?: Apollo.MutationHookOptions<SaveWartungMutation, SaveWartungMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveWartungMutation, SaveWartungMutationVariables>(SaveWartungDocument, options);
      }
export type SaveWartungMutationHookResult = ReturnType<typeof useSaveWartungMutation>;
export type SaveWartungMutationResult = Apollo.MutationResult<SaveWartungMutation>;
export type SaveWartungMutationOptions = Apollo.BaseMutationOptions<SaveWartungMutation, SaveWartungMutationVariables>;