import { DatePicker, DayOfWeek } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { datePickerStrings, formatDate } from "../lang";

export function DatePickerColumn<T>(props: {
  item: T;
  keyselector: keyof T;
  onChange: (date: Date | null | undefined) => void;
}) {
  const [show, setShow] = useState(false);
  const { item } = props;
  const changeShow = useCallback(() => {
    setShow(!show);
  }, [show]);

  return show ? (
    <DatePicker
      placeholder="Wähle ein Datum."
      ariaLabel="Wähle ein Datum."
      firstDayOfWeek={DayOfWeek.Sunday}
      strings={datePickerStrings}
      onSelectDate={(a) => {
        props.onChange(a);
        changeShow();
      }}
      formatDate={formatDate}
      value={
        (item[props.keyselector] as string)
          ? new Date(item[props.keyselector] as string)
          : new Date()
      }
    />
  ) : (
    <div style={{ width: "100%", height: "100%" }} onClick={changeShow}>
      {new Date(item[props.keyselector] as string).getDate() +
        "." +
        (new Date(item[props.keyselector] as string).getMonth() + 1) +
        "." +
        new Date(item[props.keyselector] as string).getFullYear() ?? ""}
    </div>
  );
}
