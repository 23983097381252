import React, { useCallback, useEffect, useState } from "react";
import { DismissRegular } from "@fluentui/react-icons";
import {
  DialogActions,
  DialogBody,
  DialogSurface,
  DialogTitle,
  FluentProvider,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  webLightTheme,
  Dialog,
  Button,
} from "@fluentui/react-components";
import s from "./TavsView.module.css";

export default function TabsView(props: {
  onTabSelect: (item: string) => void;
  selectedValue?: string;
  tabs: Array<{ id: string; element: React.ReactElement; warn?: boolean }>;
  onClose: (id: string) => void;
}) {
  const [tabs, setTabs] = useState(Array.from(new Set(props.tabs)));
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (
      tabs
        .map((pr) => pr.id)
        .sort()
        .toString() !==
      props.tabs
        .map((pr) => pr.id)
        .sort()
        .toString()
    ) {
      if (props.tabs.length !== tabs.length) {
        props.onTabSelect(
          props.tabs.map((pr) => pr.id)[
            props.tabs.map((pr) => pr.id).length - 1
          ]
        );
        setTabs(Array.from(new Set(props.tabs)));
      } else {
        setTabs(Array.from(new Set(props.tabs)));
      }
    }
  }, [tabs, props]);
  const onTabSelect = useCallback(
    (event: SelectTabEvent, data: SelectTabData) => {
      props.onTabSelect(data.value as string);
    },
    [props]
  );

  const mapper = useCallback(
    (pr: { id: string; element: React.ReactElement; warn?: boolean }) => {
      return (
        <Tab
          id={pr.id}
          value={pr.id}
          className={pr.id === props.selectedValue ? s.selected : undefined}
          content={
            <>
              {pr.id}
              {pr.id !== "Start" ? (
                <DismissRegular
                  onClick={() => {
                    if (pr.warn) {
                      setOpen(true);
                    } else {
                      props.onClose(pr.id);
                    }
                  }}
                />
              ) : (
                <></>
              )}
            </>
          }
        ></Tab>
      );
    },
    [props]
  );

  return (
    <FluentProvider theme={webLightTheme}>
      <TabList selectedValue={props.selectedValue} onTabSelect={onTabSelect}>
        {tabs.map(mapper)}
      </TabList>
      <div>
        {tabs.find((pr) => pr?.id === (props.selectedValue as string))
          ?.element ?? <></>}
      </div>
      {open ? (
        <Dialog open={open}>
          <DialogSurface>
            <DialogTitle>Tab Schliessen?</DialogTitle>
            <DialogBody>
              Beim Schliesen dieses Tabs gehen alle nicht gespeicherte Daten
              verloren. Wollt Ihr den Tab Schliessen?
            </DialogBody>
            <DialogActions>
              <Button
                onClick={() => {
                  let id = tabs.find(
                    (tab) => tab.id === props.selectedValue
                  )?.id;
                  if (id !== undefined) {
                    props.onClose(id);
                  }
                  setOpen(false);
                }}
                style={{ backgroundColor: "#B1DCE0" }}
                appearance="primary"
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                appearance="secondary"
              >
                No
              </Button>
            </DialogActions>
          </DialogSurface>
        </Dialog>
      ) : null}
    </FluentProvider>
  );
}
