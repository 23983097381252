import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dokumente,
  GetDokumenteDocument,
  useGetDokumenteQuery,
  useRemoveDocumentsMutation,
} from "../gql/graphql";
import { BaseButton, IColumn, IconButton } from "@fluentui/react";
import { Page } from "../components/Page";
import DetailsListEditable from "../components/DetailsListEditable";
import { DokumentPDFView } from "./DokumentPDFView";
import { DokumentUpload } from "./DokumentUpload";
import { RoleType } from "../components/Content";

interface IDokumenteViewProps {
  role: RoleType;
}

export function DokumenteView(props: IDokumenteViewProps) {
  const [pdf, setPdf] = useState<string | undefined>();
  const { loading, data } = useGetDokumenteQuery();
  const [remove] = useRemoveDocumentsMutation({
    refetchQueries: (res) => [{ query: GetDokumenteDocument }],
  });

  const [docs, setDocs] = useState<Array<Dokumente & { rowid: number }>>();
  const [showOpenDialog, setShowOpenDialog] = useState(false);

  useEffect(() => {
    if (
      !loading &&
      JSON.stringify(data?.dokumente) !==
        JSON.stringify(
          docs?.map((pr) => {
            let { rowid, ...innerpr } = pr;
            return innerpr;
          })
        )
    ) {
      setDocs(data?.dokumente.map((pr, i) => ({ ...pr, rowid: i })));
    }
  }, [docs, loading, data]);

  const onRenderFile = useCallback(
    (
      item?: Dokumente,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      if (pdf) {
        return (
          <div className="prueffilewrap">
            <IconButton
              style={{ color: "#B1DCE0" }}
              iconProps={{ iconName: "PDF" }}
              title={item?.file_name}
              ariaLabel={item?.file_name}
            />

            <DokumentPDFView
              show={!!pdf && pdf === item?.file_name}
              path={pdf}
              onDismiss={() => setPdf(undefined)}
            />
          </div>
        );
      }

      return (
        <IconButton
          style={{ color: "#B1DCE0" }}
          onClick={() => {
            if (item) setPdf(item?.file_name);
          }}
          iconProps={{ iconName: "PDF" }}
          title={item?.file_name}
          ariaLabel={item?.file_name}
        />
      );
    },
    [pdf]
  );

  const belagcolumns: IColumn[] = useMemo(
    () => [
      {
        key: "file",
        name: "Datei",
        fieldName: "file_name",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        onRender: onRenderFile,
      },
      {
        key: "title",
        name: "Name",
        fieldName: "title",
        minWidth: 400,
        maxWidth: 400,
        isResizable: true,
      },
      {
        key: "desc",
        name: "Beschreibung",
        fieldName: "description_text",
        minWidth: 880,
        maxWidth: 880,
        isResizable: true,
      },
    ],
    [onRenderFile]
  );

  const handleAddDoc = useCallback<
    React.MouseEventHandler<
      | HTMLDivElement
      | HTMLAnchorElement
      | HTMLButtonElement
      | BaseButton
      | HTMLSpanElement
    >
  >(
    (e) => {
      if (showOpenDialog) {
        setShowOpenDialog(false);
      } else {
        setShowOpenDialog(true);
      }
    },
    [showOpenDialog]
  );

  const handleRemoveDoc = useCallback(
    (
      selecteditems: (Dokumente & {
        rowid: number;
      })[]
    ) => {
      remove({
        variables: {
          ids: selecteditems
            .filter((pr) => pr.id)
            .map((pr) => pr.id) as number[],
        },
      });
    },
    [remove]
  );

  return (
    <Page scroll title="Dokumente">
      <div className="documentsView">
        {!loading ? (
          <>
            <DetailsListEditable<Dokumente>
              noEdit={
                !["Admin", "Firmen Admin", "GV", "Mischmeister"].includes(
                  props.role
                )
              }
              onAdd={handleAddDoc}
              data={docs ?? []}
              onRemoveClick={handleRemoveDoc}
              columns={belagcolumns}
            />
            {showOpenDialog ? (
              <DokumentUpload
                show={showOpenDialog}
                onDismiss={() => {
                  setShowOpenDialog(false);
                }}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </Page>
  );
}
