import * as React from "react";
import {
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerOption,
  TagPickerGroup,
  TagPickerProps,
} from "@fluentui/react-components";
import { Tag, Avatar } from "@fluentui/react-components";
import { useHideUnimportantErrors } from "./hooks/useHideUninportantErrors";

export function EinbauMaschinePicker(props: {
  einbaumaschinen: string[];
  onChange: (items?: string[]) => void;
  einbaumaschinenOptionen: string[];
  disabled?: boolean;
  style?: React.CSSProperties & {
    "--fui-TagPickerControl-aside-width"?: string | number | undefined;
  };
}) {
  const onOptionSelect: TagPickerProps["onOptionSelect"] = React.useCallback(
    (e: any, data: any) => {
      props.onChange(data.selectedOptions);
    },
    [props]
  );

  const tagPickerOptions = React.useMemo(
    () =>
      props.einbaumaschinenOptionen.filter(
        (option) => !props.einbaumaschinen.includes(option)
      ),
    [props]
  );
useHideUnimportantErrors();
  return (
    <TagPicker
      onOptionSelect={onOptionSelect}
      selectedOptions={props.einbaumaschinen}
      disabled={props.disabled}
    >
      <TagPickerControl style={props.style}>
        <TagPickerGroup>
          {props.einbaumaschinen.map((option) => (
            <Tag
              key={option}
              shape="circular"
              media={<Avatar aria-hidden name={option} color="colorful" />}
              value={option}
              disabled={props.disabled}
            >
              {option}
            </Tag>
          ))}
        </TagPickerGroup>
        <TagPickerInput aria-label="Select Employees" />
      </TagPickerControl>
      <TagPickerList>
        {tagPickerOptions.length > 0
          ? tagPickerOptions.map((option) => (
              <TagPickerOption
                media={
                  <Avatar
                    shape="circular"
                    aria-hidden
                    name={option}
                    color="colorful"
                  />
                }
                value={option}
                key={option}
              >
                {option}
              </TagPickerOption>
            ))
          : "Es sind keine Einbaumaschienen mehr verfügbar"}
      </TagPickerList>
    </TagPicker>
  );
}
