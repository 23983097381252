import React, { useCallback, useState } from "react";
import image from "../tozzologo.png";
import { User } from "../gql/graphql";
import { Persona, PersonaSize } from "@fluentui/react";

function Header(props: {
  user: User;
  onLogout: () => void;
  onToggleSandwitch: () => void;
}) {
  return (
    <nav className="sb-topnav navbar navbar-expand navbar-light bg-light">
      <img style={{ marginLeft: 10 }} src={image} width={"10%"} alt="logo" />
      <button
        onClick={props.onToggleSandwitch}
        className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
        id="sidebarToggle"
      >
        <i className="fas fa-bars"></i>
      </button>
      <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
        <div className="input-group"></div>
      </form>
      <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
        <li style={{ marginTop: 5 }} className="nav-item ">
          <Persona
            size={PersonaSize.size32}
            text={props.user.upn}
            secondaryText={props.user.upn}
            aria-label="Profile Menü"
          >
            <p className="logout" onClick={props.onLogout}>
              Logout
            </p>
          </Persona>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
