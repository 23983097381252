import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import {
  BaseButton,
  ContextualMenu,
  DatePicker,
  DayOfWeek,
  Dialog,
  DialogType,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import "react-quill/dist/quill.snow.css";
import { Field } from "@fluentui/react-components";
import { News, User } from "../gql/graphql";
import { datePickerStrings, formatDate } from "../lang";

export function parseFromLacaleDateToNormal(dateString: string) {
  if (dateString.split(".").length === 3) {
    const [day, month, year] = dateString.split(".");
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    return date.toString();
  }
  return dateString;
}

// Editor is an uncontrolled React component
export function NachrichtForm(props: {
  onAdd: (item: Partial<News> & { rowid?: number }) => void;
  news?: Partial<News> & { rowid?: number };
  user: User;
  show?: boolean;
  onDismiss: () => void;
}) {
  const [value, setValue] = useState(props.news?.text ?? "");
  const [titel, setTitel] = useState(props.news?.titel ?? "");
  const [datum, setDatum] = useState(
    props.news?.datum
      ? parseFromLacaleDateToNormal(props.news?.datum)
      : new Date().toString()
  );
  const [rowid, setRowid] = useState(props.news?.rowid);

  useEffect(() => {
    if (
      props.news?.rowid !== rowid &&
      props.news?.text !== value &&
      props.news?.titel !== titel &&
      props.news?.datum !== datum
    ) {
      setRowid(props.news?.rowid);
      setValue(props.news?.text ?? "");
      setTitel(props.news?.titel ?? "");
      setDatum(
        props.news?.datum
          ? parseFromLacaleDateToNormal(props.news?.datum)
          : new Date().toString()
      );
    }
  }, [props, datum, rowid, value]);
  const dragOptions = useMemo(
    () => ({
      moveMenuItemText: "Move",
      closeMenuItemText: "Close",
      menu: ContextualMenu,
    }),
    []
  );
  const modalPropsStyles = useMemo(
    () => ({ main: { maxWidth: 750, minWidth: 750 } }),
    []
  );
  const modalProps = React.useMemo(
    () => ({
      isBlocking: true,
      styles: modalPropsStyles,
      dragOptions: dragOptions,
    }),
    [dragOptions, modalPropsStyles]
  );
  const dialogContentProps = {
    type: DialogType.normal,
    title: "Nachricht",
    subText: "Erfasse eine Nachricht die du allen zeigen willst",
  };

  const handleTitleChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue) {
        setTitel(newValue);
      }
    },
    []
  );

  const handleOnAddClick: React.MouseEventHandler<
    | HTMLDivElement
    | HTMLAnchorElement
    | HTMLButtonElement
    | BaseButton
    | HTMLSpanElement
  > = useCallback(
    (e) => {
      let datumtmp = new Date(datum);
      let datestr = formatDate(datumtmp);
      props.onAdd({
        rowid: props.news?.rowid,
        text: value,
        titel,
        datum: datestr,
        ersteller: props.news?.ersteller ?? props.user.upn,
        id: props.news?.id,
      });
    },
    [props, value, titel, datum]
  );
  return (
    <Dialog
      hidden={!props.show}
      onDismiss={props.onDismiss}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      minWidth={"40vw"}
      maxWidth={"50vw"}
    >
      <div style={{ minHeight: "40vh" }}>
        <Field orientation={"horizontal"} label={"Titel:"}>
          <TextField value={titel} onChange={handleTitleChange} />
        </Field>
        <Field
          style={{ paddingTop: 15 }}
          orientation={"horizontal"}
          label={"Datum:"}
        >
          <DatePicker
            placeholder="Wähle ein Datum."
            ariaLabel="Wähle ein Datum."
            firstDayOfWeek={DayOfWeek.Sunday}
            strings={datePickerStrings}
            onSelectDate={(a) => {
              if (a) {
                setDatum(a?.toString());
              }
            }}
            formatDate={formatDate}
            value={
              datum ? new Date(parseFromLacaleDateToNormal(datum)) : new Date()
            }
          />
        </Field>
        <Field orientation={"vertical"} label={"Nachricht"}>
          <ReactQuill
            style={{ marginRight: 20 }}
            theme="snow"
            value={value}
            onChange={setValue}
          />
        </Field>
        <Field style={{ marginTop: 60 }}>
          <PrimaryButton style={{ maxWidth: 200 }} onClick={handleOnAddClick}>
            Speichern/Hinzufügen
          </PrimaryButton>
        </Field>
      </div>
    </Dialog>
  );
}
