import { ScrollablePane, mergeStyleSets } from "@fluentui/react";
import React from "react";

const classNames = mergeStyleSets({
  wrapper: {
    height: "80vh",
    position: "relative",
    maxHeight: "80vh",
  },
});

export function Page(props: {
  scroll?: boolean;
  title: string;
  children: React.ReactElement[] | React.ReactElement;
}) {
  return props.scroll ? (
    <div className={classNames.wrapper}>
      <ScrollablePane
        scrollContainerFocus={true}
        scrollContainerAriaLabel="Bestellungs daten"
      >
        <div className="container-fluid px-4">
          <h1 className="mt-4">{props.title}</h1>
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item active">{props.title}</li>
          </ol>
          {props.children}
        </div>
      </ScrollablePane>
    </div>
  ) : (
    <div className="container-fluid px-4">
      <h1 className="mt-4">{props.title}</h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item active">{props.title}</li>
      </ol>
      {props.children}
    </div>
  );
}
