import {
  ContextualMenu,
  Dialog,
  DialogType,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { Field } from "@fluentui/react-components";
import React, { useCallback, useMemo, useState } from "react";
import {
  AddDocumentMutationVariables,
  GetDokumenteDocument,
  useAddDocumentMutation,
} from "../gql/graphql";

export function DokumentUpload(props: {
  show: boolean;
  onDismiss: (
    ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
}) {
  const [add] = useAddDocumentMutation({
    refetchQueries: (res) => [{ query: GetDokumenteDocument }],
  });
  const [file, setFile] = useState<string | undefined>();
  const [name, setName] = useState<string>();
  const [desc, setDesc] = useState<string>();
  const [filename, setFilename] = useState<string>();

  const formdata = useMemo<AddDocumentMutationVariables | undefined>(() => {
    if (
      name &&
      desc &&
      file &&
      file != null &&
      filename &&
      filename.endsWith(".pdf")
    ) {
      return { title: name, desc, input: file, filename };
    }
  }, [filename, name, desc, file]);

  const dragOptions = useMemo(
    () => ({
      moveMenuItemText: "Move",
      closeMenuItemText: "Close",
      menu: ContextualMenu,
    }),
    []
  );
  const modalPropsStyles = useMemo(
    () => ({ main: { maxWidth: 750, minWidth: 750 } }),
    []
  );
  const modalProps = React.useMemo(
    () => ({
      isBlocking: true,
      styles: modalPropsStyles,
      dragOptions: dragOptions,
    }),
    [dragOptions, modalPropsStyles]
  );
  const dialogContentProps = {
    type: DialogType.normal,
    title: "Dokument",
    subText: "Dokument hinzufügen",
  };
  const handleAddDoc = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      if (e?.target?.files) {
        const fileloaded = e?.target?.files[0];
        setFilename(fileloaded.name);
        const reader = new FileReader();

        reader.onloadend = function () {
          const base64String = reader.result as string;

          setFile(base64String);
        };
        reader.readAsDataURL(fileloaded);
      }
    },
    [setFile, setFilename]
  );
  return (
    <Dialog
      hidden={!props.show}
      onDismiss={props.onDismiss}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      maxWidth={800}
      minWidth={800}
    >
      <Field style={{ paddingTop: 10 }} orientation="horizontal" label="Name">
        <TextField
          title="Name"
          value={name}
          onChange={(e, val) => {
            setName(val);
          }}
        />
      </Field>
      <Field
        style={{ paddingTop: 10 }}
        orientation="horizontal"
        label="Beschreibung"
      >
        <TextField
          title="Beschreibung"
          value={desc}
          onChange={(e, val) => {
            setDesc(val);
          }}
        />
      </Field>

      <Field style={{ paddingTop: 10 }} orientation="horizontal" label="File">
        <input type="file" onChange={handleAddDoc} />
      </Field>
      <PrimaryButton
        disabled={!formdata}
        onClick={() => {
          if (formdata) {
            add({ variables: formdata });
            props.onDismiss();
          }
        }}
      >
        Hochladen
      </PrimaryButton>
    </Dialog>
  );
}
