import React, { useEffect } from "react";
import {
  useGetBelagsSortePdfLazyQuery,
  useGetBelagsbestellungPdfLazyQuery,
} from "../../gql/graphql";
import { PDFViewer } from "../../components/pdf/PDFViewer";

export function BelagsSortePDFView(props: {
  id?: number;
  onDismiss: (
    ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
  show: boolean;
  path?: string;
}) {
  const [getPDF, { loading, data: dataBelag }] =
    useGetBelagsSortePdfLazyQuery();
  const [getBestellPDF, { loading: loadingBestell, data: dataBestell }] =
    useGetBelagsbestellungPdfLazyQuery();

  function byteToUint8Array(byteArray: any[]) {
    var uint8Array = new Uint8Array(byteArray.length);
    for (var i = 0; i < uint8Array.length; i++) {
      uint8Array[i] = byteArray[i];
    }
    return uint8Array;
  }
  useEffect(() => {
    if (props.id && !dataBelag && props.show) {
      getPDF({ variables: { id: props.id } });
    } else if (props.path && !dataBestell && props.show) {
      getBestellPDF({ variables: { path: props.path } });
    }
  }, [getPDF, getBestellPDF, dataBelag, dataBestell, props]);
  if (props.show && !loading && dataBelag?.belagPdf) {
    return (
      <PDFViewer
        show={props.show}
        pdfBytes={byteToUint8Array(dataBelag?.belagPdf as [])}
        onDismiss={props.onDismiss}
      />
    );
  } else if (
    props.show &&
    !loadingBestell &&
    dataBestell?.belagsbestellungPdf
  ) {
    return (
      <PDFViewer
        show={props.show}
        pdfBytes={byteToUint8Array(dataBestell?.belagsbestellungPdf as [])}
        onDismiss={props.onDismiss}
      />
    );
  } else {
    return <></>;
  }
}
