import { TextField } from "@fluentui/react";
import React, { FormEvent, useCallback, useState } from "react";

interface ITextBoxColumnProps<T> {
  onChange: (
    e: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => void;
  item: T;
  keyOfItem: keyof T;
  children?: React.ReactElement;
  onFocusGoneConvertingMethode?: (str: string) => string;
}

export function TextBoxColumn<T>(props: ITextBoxColumnProps<T>) {
  const [show, setShow] = useState(false);
  const { onChange, item } = props;
  const changeShow = useCallback(() => {
    setShow(!show);
  }, [show]);

  return show ? (
    props.children ? (
      <div onBlur={changeShow}>{props.children}</div>
    ) : (
      <TextField
        onBlur={changeShow}
        onChange={onChange}
        value={item[props.keyOfItem] ? item[props.keyOfItem] + "" : undefined}
      />
    )
  ) : (
    <div style={{ width: "100%", height: "100%" }} onClick={changeShow}>
      {item[props.keyOfItem]
        ? props.onFocusGoneConvertingMethode
          ? props.onFocusGoneConvertingMethode(item[props.keyOfItem] + "")
          : item[props.keyOfItem] + ""
        : undefined}
    </div>
  );
}
