import React from "react";
import { Divider } from "@fluentui/react-components";
import s from "./Group.module.css";

export function Group(props: {
  title: string;
  children: React.ReactElement | React.ReactElement[];
  paddingTop?: number;
}) {
  return (
    <>
      <Divider
        style={{ paddingTop: props.paddingTop ?? 50 }}
        color="#B1DCE0"
        className={s.group}
        appearance="brand"
      >
        {props.title}
      </Divider>
      <div>{props.children}</div>
    </>
  );
}
