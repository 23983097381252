import React, { useState } from "react";
import { Bestellung, User, useGetBestellArchivLazyQuery } from "../gql/graphql";
import {
  Dialog,
  Dropdown,
  PrimaryButton,
  IColumn,
  Toggle,
  Icon,
  DefaultButton,
} from "@fluentui/react";
import { Field } from "@fluentui/react-components";
import DetailsListEditable from "../components/DetailsListEditable";
import { RoleType } from "../components/Content";
import { durationToTime } from "./hooks/useInitializeFormData";
function getDateRangeArray() {
  let nrs = new Array<number>();
  let startnr = 2010;
  let endnr = new Date(Date.now()).getFullYear();
  while (startnr !== endnr) {
    startnr++;
    nrs.push(startnr);
  }
  return nrs;
}
export function BestellungArchiv(props: {
  onOpenBestellung: (bestellung?: Bestellung) => void;
  role: RoleType;
  user?: User;
}) {
  const [open, setOpen] = useState(true);
  const [freigegeben, setFreigegeben] = useState(false);
  const [nurmeine, setNurMeine] = useState(true);
  const [month, setMonth] = useState<number>(
    new Date(Date.now()).getMonth() + 1
  );
  const [jahr, setJahr] = useState(new Date(Date.now()).getFullYear());

  const [load, { data: dtaQry, loading }] = useGetBestellArchivLazyQuery({
    variables: { monat: month, freigabe: freigegeben, nurmeine, jahr },
    onCompleted: (data) => {
      setOpen(false);
      setData(data);
    },
  });

  const [data, setData] = useState(dtaQry);
  const columns: IColumn[] = [
    {
      key: "bid",
      fieldName: "bid",
      name: "ID",
      minWidth: 10,

      maxWidth: 50,
      onRender: (item: Bestellung, ev, column) => {
        return <p onClick={() => props.onOpenBestellung(item)}>{item.bid}</p>;
      },
    },
    {
      key: "mischwerk",
      minWidth: 120,
      maxWidth: 180,

      fieldName: "mischwerk",
      name: "Mischwerk",
      onRender: (item: Bestellung, ev, column) => {
        return (
          <p onClick={() => props.onOpenBestellung(item)}>{item.mischwerk}</p>
        );
      },
    },
    {
      key: "baustelle_Name",
      minWidth: 100,
      maxWidth: 400,

      fieldName: "baustelle_Name",
      name: "Baustelle",
      onRender: (item: Bestellung, ev, column) => {
        return (
          <p onClick={() => props.onOpenBestellung(item)}>
            {item.baustelle_Name}
          </p>
        );
      },
    },
    {
      key: "datum",
      minWidth: 50,
      maxWidth: 80,

      fieldName: "datun",
      name: "Datum",
      onRender: (item: Bestellung, ev, column) => {
        return (
          <p onClick={() => props.onOpenBestellung(item)}>
            {new Date(item.datum).getDate()}.
            {new Date(item.datum).getMonth() + 1}.
            {new Date(item.datum).getFullYear()}
          </p>
        );
      },
    },
    {
      key: "einbaubegin",
      minWidth: 50,
      maxWidth: 80,

      fieldName: "einbaubegin",
      name: "Einbaubegin",
      onRender: (item: Bestellung, ev, column) => {
        return (
          <p onClick={() => props.onOpenBestellung(item)}>
            {durationToTime(item.einbaubegin)}
          </p>
        );
      },
    },
    {
      key: "bestellt",
      minWidth: 50,
      maxWidth: 80,

      fieldName: "bestellt",
      name: "Bestellt",
      onRender: (item: Bestellung, ev, column) => {
        return (
          <p onClick={() => props.onOpenBestellung(item)}>
            {item.bestellt === 1 ? (
              <Icon style={{ color: "green" }} iconName="Accept" />
            ) : (
              <Icon style={{ color: "red" }} iconName="Clear" />
            )}
          </p>
        );
      },
    },
    {
      key: "freigegeben",
      minWidth: 50,
      maxWidth: 80,
      fieldName: "freigegeben",
      name: "Freigegeben",
      onRender: (item: Bestellung, ev, column) => {
        return (
          <p onClick={() => props.onOpenBestellung(item)}>
            {item.freigegeben === 1 ? (
              <Icon style={{ color: "green" }} iconName="Accept" />
            ) : (
              <Icon style={{ color: "red" }} iconName="Clear" />
            )}
          </p>
        );
      },
    },
    {
      key: "bestaetigt",
      minWidth: 50,
      maxWidth: 80,
      fieldName: "bestaetigt",
      name: "Bestaetigt",
      onRender: (item: Bestellung, ev, column) => {
        return (
          <p onClick={() => props.onOpenBestellung(item)}>
            {item.bestaetigt === 1 ? (
              <Icon style={{ color: "green" }} iconName="Accept" />
            ) : (
              <Icon style={{ color: "red" }} iconName="Clear" />
            )}
          </p>
        );
      },
    },
    {
      key: "storniert",
      minWidth: 50,
      maxWidth: 80,
      fieldName: "storniert",
      name: "Storniert",
      onRender: (item: Bestellung, ev, column) => {
        return (
          <p onClick={() => props.onOpenBestellung(item)}>
            {item.storniert === 1 ? (
              <Icon style={{ color: "green" }} iconName="Accept" />
            ) : (
              <Icon style={{ color: "red" }} iconName="Clear" />
            )}
          </p>
        );
      },
    },
  ];

  return (
    <div style={{ padding: 20 }} className="archiv">
      <Dialog hidden={!open} onDismiss={() => setOpen(false)}>
        <Field style={{ paddingTop: 10 }} orientation="horizontal" label="Jahr">
          <Dropdown
            options={getDateRangeArray().map((pr) => ({
              key: pr,
              text: String(pr),
            }))}
            onChange={(e, o) => {
              let jahrtmp = (o?.key ?? 1) as number;
              setJahr(jahrtmp);
            }}
            selectedKey={jahr}
          />
        </Field>
        <Field
          style={{ paddingTop: 10 }}
          orientation="horizontal"
          label="Monat"
        >
          <Dropdown
            options={[
              "Januar",
              "Februar",
              "März",
              "April",
              "Mai",
              "Juni",
              "Juli",
              "August",
              "September",
              "Oktober",
              "November",
              "Dezember",
            ].map((pr, i) => ({ key: i + 1, text: pr }))}
            onChange={(e, o) => {
              let monthtmp = ((o?.key ?? 1) as number) + 1;
              setMonth(monthtmp);
            }}
            selectedKey={month}
          />
        </Field>
        <Field
          style={{ paddingTop: 10 }}
          orientation="horizontal"
          label="Freigegeben"
        >
          <Toggle
            onChange={(e, c) => {
              if (c !== undefined) {
                setFreigegeben(c);
              }
            }}
            checked={freigegeben}
          />
        </Field>
        <Field
          style={{ paddingTop: 10 }}
          orientation="horizontal"
          label="Nur meine"
        >
          <Toggle
            onChange={(e, c) => {
              if (c !== undefined) {
                setNurMeine(c);
              }
            }}
            checked={nurmeine}
          />
        </Field>
        <br />
        <PrimaryButton onClick={() => load()}>Suchen</PrimaryButton>
      </Dialog>

      {!loading && data ? (
        <div className="archivTable">
          <DefaultButton onClick={() => setOpen(true)}>
            Neue Suche
          </DefaultButton>

          <DetailsListEditable<Bestellung>
            columns={columns}
            onAdd={() => {}}
            onRemoveClick={() => {}}
            noEdit
            data={
              data.bestellungDataArchiv.map((pr, i) => ({ ...pr, rowid: i })) ??
              []
            }
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
