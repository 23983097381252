import {
  ContextualMenu,
  Dialog,
  DialogFooter,
  DialogType,
  IComboBoxOption,
  PrimaryButton,
} from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { Belag, Belagbestellung, useGetBelagQuery } from "../gql/graphql";
import { SelectField } from "../components/SelectField";

const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
};
const modalPropsStyles = { main: { maxWidth: 450 } };
const dialogContentProps = {
  type: DialogType.normal,
  title: "Belagssorte",
  subText: "Wähle den Belag aus",
};
export enum EditMode {
  Edit,
  Add,
}
export function BelagModal(props: {
  bestellid?: number;
  mode: EditMode;
  BSConcatName?: string;
  show?: boolean;
  onDismiss: (
    ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
  onAdd: (item: Belagbestellung) => void;
}) {
  const { data } = useGetBelagQuery({ variables: { inaktiv: false } });
  const [selectedBelag, setSelectedBelag] = useState<Belag | undefined>(
    props.BSConcatName
      ? data?.belag.find((pr) => pr.bSconcatName === props.BSConcatName)
      : undefined
  );

  const modalProps = React.useMemo(
    () => ({
      isBlocking: true,
      styles: modalPropsStyles,
      dragOptions: dragOptions,
    }),
    []
  );

  const CreateOption = useCallback<(item: Partial<Belag>) => IComboBoxOption>(
    (item) => {
      return {
        key: item?.bSconcatName ?? "",
        text: item?.bSconcatName ?? "",
        data: item,
      };
    },
    []
  );

  const handleSelectBelag = useCallback((val: string, data?: Belag) => {
    setSelectedBelag(data);
  }, []);
  return (
    <Dialog
      hidden={!props.show}
      onDismiss={props.onDismiss}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <SelectField<Belag>
        finder={(pr) => pr.bSconcatName === props.BSConcatName}
        options={data?.belag ?? []}
        optionBuilder={CreateOption}
        onChange={handleSelectBelag}
        value={selectedBelag?.bSconcatName ?? ""}
        title="Belag"
        id="Belag"
        defaultValue={props.BSConcatName ?? ""}
      />
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            if (props.mode === EditMode.Add) {
              let item: Belagbestellung = {
                belag: selectedBelag?.id,
                belagssorte: selectedBelag?.sorte,
                bestellid: props.bestellid,
                bindemittel: selectedBelag?.bSbinder,
                typ: selectedBelag?.bStyp,
                bSconcatName: selectedBelag?.bSconcatName,
                rc_Anteil: selectedBelag?.bSrcp,
                erstPruefFile: selectedBelag?.erstPruefFile,
              };
              props.onAdd(item);
            }
          }}
          text="Send"
        />
      </DialogFooter>
    </Dialog>
  );
}
