import React, { useEffect } from "react";
import { PDFViewer } from "../components/pdf/PDFViewer";
import { useGetReportLazyQuery } from "../gql/graphql";

export function BestellungPDFView(props: {
  onDismiss: (
    ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
  show: boolean;
  path: string;
}) {
  const [getPDF, { loading, data }] = useGetReportLazyQuery();

  function byteToUint8Array(byteArray: any[]) {
    var uint8Array = new Uint8Array(byteArray.length);
    for (var i = 0; i < uint8Array.length; i++) {
      uint8Array[i] = byteArray[i];
    }
    return uint8Array;
  }
  useEffect(() => {
    if (props.path && !data && props.show) {
      getPDF({ variables: { filename: props.path } });
    }
  }, [getPDF, data, props]);
  if (props.show && !loading && data?.reportPdf) {
    return (
      <PDFViewer
        show={props.show}
        pdfBytes={byteToUint8Array(data?.reportPdf as [])}
        onDismiss={props.onDismiss}
      />
    );
  } else {
    return <></>;
  }
}
