import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IColumn, ComboBox } from "@fluentui/react";
import {
  GetWartungsintervalleDocument,
  SaveWartungMutation,
  User,
  Wartung,
  WartungInput,
  useGetWartungsintervalleQuery,
  useSaveWartungMutation,
} from "../gql/graphql";
import DetailsListEditable from "../components/DetailsListEditable";
import { TextBoxColumn } from "../components/TextBoxColumn";
import { Page } from "../components/Page";
import { useHandleOfType } from "../useHandleOf";
import { ZeitColumn } from "../components/ZeitColumn";
import { DatePickerColumn } from "../components/DatePickerColumn";

export function WartungsListe(props: { user: User }) {
  const { data, loading } = useGetWartungsintervalleQuery();
  const [loadet, setLoadet] = useState(false);
  const [wartungen, setWartungen] = useState<
    (Partial<Wartung> & { rowid: number })[] | undefined
  >();

  const [save, { data: mutateData, loading: muttateloading }] =
    useSaveWartungMutation();
  useEffect(() => {
    if (!loadet && !loading) {
      setLoadet(true);
      const sortedList = [...(data?.wartung ?? [])]
        .sort((a, b) => {
          if (a.von && b.von) {
            return new Date(a.von).valueOf() - new Date(b.von).valueOf();
          } else {
            return a.id - b.id;
          }
        })
        .map((pr, i) => ({
          ...pr,
          rowid: i,
        }));

      // Update the state with a copy of the sorted list
      setWartungen(sortedList);
    }
  }, [loadet, loading, data?.wartung]);

  const handleTypChange = useHandleOfType<Partial<Wartung>>(
    "typ",
    setWartungen
  );
  const handleVonChange = useHandleOfType<Partial<Wartung>>(
    "von",
    setWartungen
  );
  const handleVontimeChange = useHandleOfType<Partial<Wartung>>(
    "vontime",
    setWartungen
  );
  const handleBistimeChange = useHandleOfType<Partial<Wartung>>(
    "bistime",
    setWartungen
  );
  const handleBisChange = useHandleOfType<Partial<Wartung>>(
    "bis",
    setWartungen
  );

  const onRenderVon = useCallback(
    (
      item?: Wartung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      if (item) {
        return (
          <DatePickerColumn<Wartung>
            keyselector={"von"}
            item={item}
            onChange={(date) => {
              if (date) {
                handleVonChange(item, date?.toISOString(), index);
              }
            }}
          />
        );
      } else {
        return <></>;
      }
    },
    [handleVonChange]
  );

  const onRenderVonTime = useCallback(
    (
      item?: Wartung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <ZeitColumn
          value={String(item?.vontime)}
          anchor={new Date(item?.von)}
          onChange={(v) => {
            handleVontimeChange(item, v, index);
          }}
        />
      );
    },
    [handleVontimeChange]
  );
  const onRenderBis = useCallback(
    (
      item?: Wartung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      if (item) {
        return (
          <DatePickerColumn<Wartung>
            keyselector={"bis"}
            item={item}
            onChange={(date) => {
              if (date) {
                handleBisChange(item, date?.toISOString(), index);
              }
            }}
          />
        );
      } else {
        return <></>;
      }
    },
    [handleBisChange]
  );

  const onRenderBisTime = useCallback(
    (
      item?: Wartung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <ZeitColumn
          value={String(item?.bistime)}
          anchor={new Date(item?.bis)}
          onChange={(v) => {
            handleBistimeChange(item, v, index);
          }}
        />
      );
    },
    [handleBistimeChange]
  );

  const onRenderTyp = useCallback(
    (
      item?: Wartung & { rowid: number },
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<Wartung>
          item={item as Wartung}
          keyOfItem="typ"
          onChange={() => {}}
        >
          <ComboBox
            selectedKey={item?.typ}
            onChange={(e, o, i, v) => handleTypChange(item, v, index)}
            options={["Technisches Problem", "Geplante Wartung"].map((pr) => ({
              key: pr,
              text: pr,
            }))}
          />
        </TextBoxColumn>
      );
    },
    [handleTypChange]
  );

  const wartungcolumns: IColumn[] = useMemo(
    () => [
      {
        key: "von",
        name: "Von",
        fieldName: "von",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        onRender: onRenderVon,
      },
      {
        key: "vontime",
        name: "",
        fieldName: "vontime",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderVonTime,
      },
      {
        key: "bis",
        name: "Bis",
        fieldName: "bis",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        onRender: onRenderBis,
      },
      {
        key: "bistime",
        name: "",
        fieldName: "bistime",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderBisTime,
      },
      {
        key: "typ",
        name: "Typ",
        fieldName: "typ",
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        onRender: onRenderTyp,
      },
      {
        key: "ersteller",
        name: "Eintrag von",
        fieldName: "ersteller",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
      },
    ],
    [onRenderBis, onRenderTyp, onRenderVon, onRenderBisTime, onRenderVonTime]
  );

  const handleMutateComplete = useCallback(
    (res: SaveWartungMutation) => {
      let tmpwart = wartungen?.map((pr) => ({
        ...pr,
        ersteller: pr.ersteller ?? props.user.upn,
      }));
      if (tmpwart) {
        setWartungen([...tmpwart]);
      }
    },
    [wartungen, props.user.upn]
  );
  return !loading ? (
    <Page title="Wartungsintervalle">
      <DetailsListEditable<Partial<Wartung>>
        hasSave
        onSaveClick={() => {
          let list = wartungen
            ?.filter(
              (pr) =>
                pr.von &&
                pr.bis &&
                pr.bistime &&
                pr.vontime &&
                pr.von !== null &&
                pr.bis !== null &&
                pr.bistime !== null &&
                pr.vontime !== null &&
                pr.typ !== null
            )
            .map<WartungInput>((pr) => ({
              bis: pr.bis,
              typ: pr.typ ?? "Technisches Problem",
              von: pr.von,
              bistime: pr.bistime,
              id: pr.id,
              vontime: pr.vontime,
            }));
          if (list) {
            save({
              variables: { rows: list },
              refetchQueries: (res) => [GetWartungsintervalleDocument],
              onCompleted: handleMutateComplete,
            });
          }
        }}
        data={wartungen ?? []}
        onAdd={() => {
          let tmp = wartungen?.map((pr) => pr.rowid) ?? [];
          let wart: Partial<Wartung> & { rowid: number } = {
            rowid: Math.max(...tmp) + 1,
            typ: "Technisches Problem",
            bis: new Date(Date.now()).toISOString(),
            von: new Date(Date.now()).toISOString(),
            bistime: "00:00",
            vontime: "00:00",
          };
          setWartungen([...(wartungen ?? []), wart]);
        }}
        onRemoveClick={(selected) => {
          let newwartungen = wartungen?.filter(
            (pr) => selected?.map((t) => t.rowid).includes(pr.rowid) === false
          );
          if (newwartungen) {
            setWartungen([...newwartungen]);
          }
        }}
        columns={wartungcolumns}
      />
    </Page>
  ) : (
    <></>
  );
}
