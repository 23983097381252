import React, { useCallback, useEffect, useState } from "react";
import {
  GetNewsDocument,
  News,
  User,
  useAddNewsMutation,
  useGetNewsQuery,
  useRemoveNewsMutation,
  useUpdateNewsMutation,
} from "../gql/graphql";
import { NachrichtForm } from "./NachrichtForm";
import DetailsListEditable from "../components/DetailsListEditable";
import { Page } from "../components/Page";

export function Nachrichten(props: { user: User }) {
  const { data, loading } = useGetNewsQuery();
  const [add] = useAddNewsMutation({
    refetchQueries: (res) => [{ query: GetNewsDocument }],
  });
  const [update] = useUpdateNewsMutation({
    refetchQueries: (res) => [{ query: GetNewsDocument }],
  });
  const [remove] = useRemoveNewsMutation({
    refetchQueries: (res) => [{ query: GetNewsDocument }],
  });
  const [news, setNews] = useState<Partial<News> & { rowid: number }[]>();
  const [initialized, setInitialized] = useState(false);
  const [selectedNews, setSelectedNews] =
    useState<Partial<News & { rowid: number }>>();

  useEffect(() => {
    if (!loading && data?.news && initialized === false) {
      setNews(
        data?.news.map((pr, i) => {
          let dt = new Date(pr.datum as string).toLocaleDateString();
          return { ...pr, datum: dt, rowid: i };
        })
      );
      setInitialized(true);
    }
  }, [loading, data, initialized]);

  const handleRowClick = useCallback(
    (u: Partial<News> & { rowid: number }) => {
      if (u.titel && u.titel !== "") {
        setSelectedNews(u);
      }
    },
    [setSelectedNews]
  );

  const handleAdd = useCallback(
    (item: Partial<News> & { rowid?: number | undefined }) => {
      if (item.id !== null && item.id !== undefined) {
        update({
          variables: {
            news: {
              datum: item.datum,
              text: item.text ?? "",
              titel: item.titel ?? "",
              id: item.id,
            },
          },
        });
        if (item.rowid !== undefined) {
          let tmpNews: (Partial<News> & { rowid: number }[]) | undefined =
            news?.filter((pr) => pr.rowid !== item.rowid);

          let tmp = {
            ...item,
            ersteller: props.user.upn,
            rowid: item.rowid ?? 0,
          };
          tmpNews?.push(tmp);
          setNews(tmpNews);
        }
      } else {
        add({
          variables: {
            news: {
              datum: item.datum,
              text: item.text ?? "",
              titel: item.titel ?? "",
            },
          },
        });
        let nrrows = news?.map((r) => r.rowid) ?? [];
        let tmp = {
          ...item,
          ersteller: props.user.upn,
          rowid: Math.max(...nrrows) + 1,
        };
        if (news) {
          setNews([...news, tmp]);
        }
      }

      setSelectedNews(undefined);
    },
    [add, news, props.user.upn, update]
  );

  return (
    <Page title="Nachrichten">
      <div className="nachrichten">
        <DetailsListEditable<Partial<News>>
          data={news ?? []}
          onAdd={() => setSelectedNews({})}
          onClickRow={handleRowClick}
          onRemoveClick={(selectedItems) => {
            selectedItems.forEach((pr) => {
              if (pr.id) {
                remove({ variables: { id: pr.id } });
              }
            });
            let newlist:
              | (Partial<News> &
                  {
                    rowid: number;
                  }[])
              | undefined = news?.filter(
              (it) => !selectedItems.map((pr) => pr.rowid).includes(it.rowid)
            );
            if (newlist) {
              setNews([...newlist]);
            }
          }}
          showFields={["titel", "text", "datum", "ersteller"]}
        />
        <NachrichtForm
          user={props.user}
          onAdd={handleAdd}
          news={selectedNews}
          show={!!selectedNews}
          onDismiss={() => {
            setSelectedNews(undefined);
          }}
        />
      </div>
    </Page>
  );
}
