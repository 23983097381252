import React, { useCallback, useEffect } from "react";
import { IPublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./AuthConfig";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  fromPromise,
} from "@apollo/client";
import { Content } from "./components/Content";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { DefaultButton } from "@fluentui/react";
import image from "./Header_Werkhof_2.png";

const MainContent = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleLoginRedirect = useCallback(() => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  }, [instance]);

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  useEffect(() => {
    if (!activeAccount) {
      handleLoginRedirect();
    }
  }, [activeAccount, handleLoginRedirect]);

  initializeIcons();

  // Step 3: Set up Apollo Client with Token Refresh Logic
  const authLink = new ApolloLink((operation, forward) => {
    return fromPromise(
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: activeAccount !== null ? activeAccount : undefined,
        })
        .then((response) => {
          // Use the new token in the headers
          operation.setContext({
            headers: {
              Authorization: `Bearer ${response.idToken}`,
            },
          });
        })
        .catch((error) => {
          console.log("Token acquisition failed, redirecting to login.", error);
          handleLoginRedirect(); // Redirect to login on failure
        })
    ).flatMap(() => forward(operation));
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(
      new HttpLink({
        uri: process.env.REACT_APP_BACKEND_ENDPOINT,
      })
    ),
  });

  return (
    <div className="App">
      <AuthenticatedTemplate>
        {activeAccount ? (
          <ApolloProvider client={client}>
            <Content handleLogoutRedirect={handleLogoutRedirect} />
          </ApolloProvider>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div
          style={{
            height: "100vh",
            width: "100vw",
            alignContent: "center",
            alignSelf: "center",
            alignItems: "center",
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="login"
          onLoad={handleLoginRedirect}
        >
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
              height: "100vh",
              width: "100vw",
              justifyItems: "center",
              justifySelf: "center",
            }}
          >
            <DefaultButton
              style={{ margin: "auto" }}
              value={"Einloggen"}
              title="Einloggen"
              onClick={handleLoginRedirect}
            >
              Einloggen
            </DefaultButton>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};

function App(props: { instance: IPublicClientApplication }) {
  return (
    <MsalProvider instance={props.instance}>
      <MainContent />
    </MsalProvider>
  );
}

export default App;
