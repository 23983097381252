import * as React from "react";
import {
  News,
  Wartung,
  useGetNewsAndWartungForStartQuery,
} from "../../gql/graphql";
import DetailsListEditable from "../DetailsListEditable";
import { IColumn, ScrollablePane } from "@fluentui/react";
import { ZeitColumn } from "../ZeitColumn";
import { Group } from "../group/Group";

export function Start() {
  const newscolumns: IColumn[] = React.useMemo(
    () => [
      {
        key: "text",
        name: "",
        fieldName: "text",
        minWidth: 400,
        maxWidth: 600,
        isResizable: true,
        onRender: (item: News) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="newscontentTitle">
              <h3>{item.titel}</h3>
              <div dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>
            <div className="newscontetText"></div>
          </div>
        ),
      },
      {
        fieldName: "ersteller",
        name: "",
        key: "ersteller",
        minWidth: 200,
        maxWidth: 200,
      },
      {
        fieldName: "datum",
        name: "",
        key: "datum",
        minWidth: 200,
        maxWidth: 200,
      },
    ],
    [ZeitColumn]
  );

  const wartungcolumns: IColumn[] = React.useMemo(
    () => [
      {
        key: "von",
        name: "Von",
        fieldName: "von",
        minWidth: 100,
        maxWidth: 100,
        onRender: (item: Wartung) => (
          <div style={{ width: "100%", height: "100%" }}>
            {new Date(item.von).getDate() +
              "." +
              (new Date(item.von).getMonth() + 1) +
              "." +
              new Date(item.von).getFullYear() ?? ""}
          </div>
        ),
      },
      {
        key: "vontime",
        name: "",
        fieldName: "vontime",
        minWidth: 100,
        maxWidth: 100,
        onRender: (item: Wartung) => (
          <ZeitColumn
            value={String(item?.vontime)}
            anchor={new Date(item?.von)}
            nonEditable
            onChange={(v) => {}}
          />
        ),
      },
      {
        key: "bis",
        name: "Bis",
        fieldName: "bis",
        minWidth: 100,
        maxWidth: 100,
        onRender: (item: Wartung) => (
          <div style={{ width: "100%", height: "100%" }}>
            {new Date(item.bis).getDate() +
              "." +
              (new Date(item.bis).getMonth() + 1) +
              "." +
              new Date(item.bis).getFullYear() ?? ""}
          </div>
        ),
      },
      {
        key: "bistime",
        name: "",
        fieldName: "bistime",
        minWidth: 100,
        maxWidth: 100,
        onRender: (item: Wartung) => (
          <ZeitColumn
            value={String(item?.bistime)}
            anchor={new Date(item?.bis)}
            nonEditable
            onChange={(v) => {}}
          />
        ),
      },
      {
        key: "typ",
        name: "Typ",
        fieldName: "typ",
        minWidth: 150,
        maxWidth: 150,
      },
      {
        key: "ersteller",
        name: "Eintrag von",
        fieldName: "ersteller",
        minWidth: 100,
        maxWidth: 100,
      },
    ],
    [ZeitColumn]
  );
  const { data, loading } = useGetNewsAndWartungForStartQuery();
  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }} className="start">
      <div style={{ height: "40vh" }} className="top">
        <Group title="Nachrichten">
          <DetailsListEditable<News>
            columns={newscolumns}
            data={
              data?.news.map((pr, i) => {
                let dat = new Date(pr.datum);
                return {
                  ...pr,
                  datum:
                    dat.getDate() +
                    "." +
                    (dat.getMonth() + 1) +
                    "." +
                    dat.getFullYear(),
                  rowid: i,
                };
              }) ?? []
            }
            noEdit
            height={"40vh"}
            onAdd={() => {}}
            onRemoveClick={() => {}}
          />
        </Group>
      </div>

      <div
        style={{ height: "40vh", justifyContent: "flex-end" }}
        className="bottom"
      >
        <Group title="Geplante Wartungen" paddingTop={20}>
          <DetailsListEditable<Wartung>
            columns={wartungcolumns}
            data={data?.wartung.map((pr, i) => ({ ...pr, rowid: i })) ?? []}
            noEdit
            height={"40vh"}
            onAdd={() => {}}
            onRemoveClick={() => {}}
          />
        </Group>
      </div>
    </div>
  );
}
